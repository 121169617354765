/**
 * Saves the current search query to the localStorage
 * @param saveToCookie: whether to update the cookie with searchData info as well
 */
function saveSearch(search, saveToCookie=false) {
    //Save the searchData
    let searchData = JSON.stringify(search);

    if (saveToCookie) {
        //Save the searchData to a cookie for direct use
        document.cookie =
          'searchData=' + searchData + '; domain=' + window.location.hostname + '; path=/; SameSite=Lax';
    }
    localStorage['searchData'] = searchData;
};

/**
 * Loads the current search query from the localStorage
 * @returns Object
 */
function getSearch() {
    try {
        return JSON.parse(localStorage['searchData']);
    } catch (e) {
        return {};
    }
};

export { saveSearch, getSearch };