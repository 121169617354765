/**
 * Inserts new element at a certain index
 * @param child
 * @param index
 */
Element.prototype.insertChildAtIndex = function(child, index) {
  if (!index) index = 0;
  if (index >= this.children.length) {
    this.appendChild(child);
  } else {
    this.insertBefore(child, this.children[index]);
  }
};

/**
 * @browser Gecko
 * @param {array} types
 * @param {Function} listener
 * @param {boolean} [useCapture]
 */
Element.prototype.addEventListeners = function(types, listener, useCapture = false) {
  for (let i = 0; i < types.length; i++) {
    this.addEventListener(types[i], listener, useCapture);
  }
};

/**
 * Checks if element has a scrollbar
 * @returns {boolean}
 */
Element.prototype.hasScrollBar = function() {
  return this.scrollHeight > this.clientHeight;
};

/**
 * Removes a single element
 */
Element.prototype.remove = function() {
  this.parentElement.removeChild(this);
};
