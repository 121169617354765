import View from '../../View';
import SearchAutoComplete from './autocomplete';
import { saveSearch, getSearch } from '../../utils/search';

class ExtensiveSearchForm extends View {
  constructor(node) {
    super(node);
    this.instances = {};
    this.searchType =
      this.node.id && this.node.id === 'im-extensive-search-form' ? 'IM' : 'kennisbank';

    this.addIncludedWordInput = this.node.querySelector('.search-input-extensive');
    this.includedWordsContainer = this.node.querySelector('.included-word-wrapper');
    this.includedWordHiddenInput = this.node.querySelector('.included-word-hidden-input');

    this.addExcludedWordButton = this.node.querySelector('.button-add-excluded-word');
    this.addIncludedWordButton = this.node.querySelector('.button-add-included-word');
    this.excludedWordsContainer = this.node.querySelector('.excluded-words-container');
    this.excludedWordInput = this.node.querySelector('.withoutWordsInput');
    this.onlyInTitleCheckbox = this.node.querySelector('.search_title');
    this.submitButton = this.node.querySelector('.submit-extensive-search-button');

    this.addExcludedWordButton.addEventListener('click', e =>
      this.addExcludedWordClick(e, true, this.searchType)
    );
    this.excludedWordInput.addEventListener('keydown', e => this.handleExcludedWordInput(e));

    this.addIncludedWordButton.addEventListener('click', e =>
      this.addIncludedWordClick(e, true, this.searchType)
    );
    this.addIncludedWordInput.addEventListener('keydown', e => this.handleIncludedWordInput(e));

    //Add default filter listener
    this.filterCheckboxes = this.node.querySelectorAll('.filter');
    this.filterCheckboxes.addEventListeners('change', e => {
      this.addCheckToSearch(e.target);
    });

    this.onlyInTitleCheckbox.addEventListener('click', e =>
      this.handleOnlyInSearchCheckboxClick(e)
    );

    this.submitButton.addEventListener('click', e => this.clickSubmitButton(e));

    //This is needed for clicking on the autosuggestions.
    events.on('addIncludedWordFromAutoSuggest', ({ event, searchType }) => {
      this.addIncludedWordClick(event, false, searchType);
    });
    events.on('addExcludedWordFromAutoSuggest', ({ event, searchType }) => {
      this.addExcludedWordClick(event, false, searchType);
    });
    events.on('matchIncludedWords', ({ searchType, allWords }) => {
      this.matchIncludedWords(searchType, allWords);
    });
    events.on('matchExcludedWords', ({ searchType, allWords }) => {
      this.matchExcludedWords(searchType, allWords);
    });
    events.on('removeIncludedWord', ({ searchType, termToRemove }) => {
      this.globalRemoveKeyword(searchType, termToRemove, 'include');
    });
    events.on('removeExcludedWord', ({ searchType, termToRemove }) => {
      this.globalRemoveKeyword(searchType, termToRemove, 'exclude');
    });
    events.on('onlyInSearchCheckboxClicked', ({ event, searchType }) => {
      if (searchType !== this.searchType) {
        this.onlyInTitleCheckbox.checked = event.target.checked;
      }
    });

    //Add optional filter listeners
    events.on('addSourceOptionListener', element => {
      this.filterCheckboxes = this.filterCheckboxes.toArray();
      this.filterCheckboxes[this.filterCheckboxes.length + 1] = element;

      element.addEventListener('change', event => {
        this.addCheckToSearch(event.target);
      });
    });

    events.on('matchIncludedWordInput', ({ event, searchType, inputValue }) => {
      if (searchType !== this.searchType) {
        this.matchIncludedWordInput(inputValue);
      }
    });
    events.on('matchExcludedWordInput', ({ event, searchType, inputValue }) => {
      if (searchType !== this.searchType) {
        this.matchExcludedWordInput(inputValue);
      }
    });
  }

  /**
   * Adds a filter to the search query
   * @param element
   */
  addCheckToSearch = element => {
    let search = getSearch();
    if (element.classList.contains('filter')) {
      const hiddenInputs = Array.from(element.parentNode.querySelectorAll('.hidden-input'));
      if (Array.isArray(hiddenInputs) && hiddenInputs.length >= 1) {
        this.changeHiddenInputs(hiddenInputs);
      }
      if (element.checked) {
        search[element.name] = element.value;
      } else {
        delete search[element.name];
      }
    }

    //Save the searchData after every search update
    saveSearch(search);
  };

  addIncludedWordClick(e, fromInput, searchType) {
    if (!fromInput && searchType !== this.searchType) return;
    if (e) {
      e.preventDefault();
    }
    const term = fromInput ? this.addIncludedWordInput.value : e.target.dataset.link;
    if (!term) return;
    const includedWord = document.createElement('span');
    includedWord.className = 'filter-word-instance';
    includedWord.dataset.term = term;
    const keywordType = 'and_with';

    includedWord.innerHTML = `
      <span>${term}</span>
      <button class="delete-included-word-button x-button"></button>
      <input type="hidden" name="keyword[]" value="${term}"/>
      <input type="hidden" class="keyword-type" name="keywordType[]" value="${keywordType}" />
    `;
    includedWord
      .querySelector('.delete-included-word-button')
      .addEventListener('click', e => this.removeKeyword(e));

    this.includedWordsContainer.appendChild(includedWord);

    const allIncludedWords = Array.from(
      this.includedWordsContainer.querySelectorAll('.filter-word-instance')
    );
    events.emit('matchIncludedWords', {
      searchType: this.searchType,
      allWords: allIncludedWords,
    });
    events.emit('clearAutoSuggest');
    this.addIncludedWordInput.value = '';
  }

  matchIncludedWordInput = inputValue => {
    this.addIncludedWordInput.value = inputValue;
    return;
  };

  matchExcludedWordInput = inputValue => {
    this.excludedWordInput.value = inputValue;
    return;
  };

  matchIncludedWords(searchType, allWords) {
    if (searchType !== this.searchType) {
      this.includedWordsContainer.innerHTML = '';
      for (let i = 0; i < allWords.length; i++) {
        const term = allWords[i].dataset.term;
        const includedWord = document.createElement('span');
        includedWord.className = 'filter-word-instance';
        includedWord.dataset.term = term;
        const keywordType = i === 0 ? 'with' : 'and_with';

        includedWord.innerHTML = `
          <span>${term}</span>
          <button class="delete-included-word-button x-button"></button>
          <input type="hidden" name="keyword[]" value="${term}"/>
          <input type="hidden" class="keyword-type" name="keywordType[]" value="${keywordType}" />
        `;
        includedWord
          .querySelector('.delete-included-word-button')
          .addEventListener('click', e => this.removeKeyword(e));
        this.includedWordsContainer.appendChild(includedWord);
      }
    }
  }

  matchExcludedWords(searchType, allWords) {
    if (searchType !== this.searchType) {
      this.excludedWordsContainer.innerHTML = '';
      for (let i = 0; i < allWords.length; i++) {
        const term = allWords[i].dataset.term;
        const withoutKeyword = document.createElement('span');
        withoutKeyword.className = 'filter-word-instance';
        withoutKeyword.dataset.term = term;

        withoutKeyword.innerHTML = `<span>${term}</span>
        <button class="delete-excluded-word-button x-button"></button>
        <input type="hidden" name="keyword[]" value="${term}"/>
        <input type="hidden" class="keyword-type" name="keywordType[]" value="without" />`;

        withoutKeyword
          .querySelector('.delete-excluded-word-button')
          .addEventListener('click', e => this.removeKeyword(e));
        this.excludedWordsContainer.appendChild(withoutKeyword);
      }
    }
  }

  handleIncludedWordInput(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      events.emit('clearAutoSuggest');
      this.addIncludedWordClick(e, true, this.searchType);
    }
  }

  handleExcludedWordInput(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      events.emit('clearAutoSuggest');
      this.addExcludedWordClick(e, true, this.searchType);
    }
  }

  removeKeyword(e) {
    e.preventDefault();
    const termToRemove = e.target.parentNode.dataset.term;
    const eventToEmit = e.target.parentNode.parentNode.classList.contains('included-word-wrapper')
      ? 'removeIncludedWord'
      : 'removeExcludedWord';

    events.emit(`${eventToEmit}`, {
      searchType: this.searchType,
      termToRemove: termToRemove,
    });

    e.target.parentNode.remove();
  }

  //When you click x in 1 of the searches, we also want to delete it in the other.
  globalRemoveKeyword(searchType, termToRemove, keywordType) {
    if (searchType === this.searchType) return;
    const container =
      keywordType === 'include' ? this.includedWordsContainer : this.excludedWordsContainer;
    const itemToRemove = Array.from(container.querySelectorAll(`[data-term='${termToRemove}']`))[0];
    itemToRemove.remove();
  }

  addExcludedWordClick(e, fromInput, searchType) {
    if (!fromInput && searchType !== this.searchType) return;
    if (e) {
      e.preventDefault();
    }
    const term = fromInput ? this.excludedWordInput.value : e.target.dataset.link;
    if (!term) return;
    const withoutKeyword = document.createElement('span');
    withoutKeyword.className = 'filter-word-instance';
    withoutKeyword.dataset.term = term;

    withoutKeyword.innerHTML = `<span>${term}</span>
    <button class="delete-excluded-word-button x-button"></button>
    <input type="hidden" name="keyword[]" value="${term}"/>
    <input type="hidden" class="keyword-type" name="keywordType[]" value="without" />`;

    this.excludedWordsContainer.appendChild(withoutKeyword);

    withoutKeyword
      .querySelector('.delete-excluded-word-button')
      .addEventListener('click', e => this.removeKeyword(e));

    this.excludedWordInput.value = '';

    const allExcludedWords = Array.from(
      this.excludedWordsContainer.querySelectorAll('.filter-word-instance')
    );
    events.emit('matchExcludedWords', {
      searchType: this.searchType,
      allWords: allExcludedWords,
    });
    events.emit('clearAutoSuggest');
  }

  handleOnlyInSearchCheckboxClick(e) {
    events.emit('onlyInSearchCheckboxClicked', { event: e, searchType: this.searchType });
  }

  changeHiddenInputs = inputs => {
    if (!Array.isArray(inputs)) return;
    for (let i = 0; i < inputs.length; i++) {
      const input = inputs[i];
      input.toggleAttribute('disabled');
    }
  };

  clickSubmitButton = e => {
    e.preventDefault();
    //Because we are working with multiple keywords (WITH, AND WITH) we have to make sure the keywordtypes are correct.
    if (this.addIncludedWordInput && this.addIncludedWordInput.value.trim() !== '') {
      this.addIncludedWordInput.name = 'keyword[]';
    } else {
      const firstIncludedWord = this.includedWordsContainer.querySelector('.filter-word-instance');
      if (firstIncludedWord !== null) {
        const hiddenInputOfFirstIncludedWord = firstIncludedWord.querySelector(
          'input[name="keywordType[]"]'
        );
        hiddenInputOfFirstIncludedWord.value = 'with';
        if (this.includedWordHiddenInput) {
          this.includedWordHiddenInput.remove();
        }
      }
    }

    if (this.excludedWordInput && this.excludedWordInput.value.trim() !== '') {
      this.excludedWordInput.name = 'keyword[]';
    }

    const form = this.node.querySelector('.extensive-search-form');
    if (!form) return;
    form.submit();
  };
}

export default ExtensiveSearchForm;
