export default {
  breakpoints: {
    small: 320,
    medium: 600,
    large: 850,
    xlarge: 1400,
    xxlarge: 1600,
    stickyHeader: 1315,
    newHeaderSearch: 1099,
  },
  mediaRanges: {
    // Ranges starting form a breakpoint
    'small-up': { min: 'small' },
    'medium-up': { min: 'medium' },
    'large-up': { min: 'large' },
    'xlarge-up': { min: 'xlarge' },
    'xxlarge-up': { min: 'xxlarge' },

    //custom ranges
    'new-header-search-up': { min: 'newHeaderSearch' },

    // Ranges between breakpoints
    'small-to-medium': { min: 'small', max: 'medium' },
    'medium-to-large': { min: 'medium', max: 'large' },

    // Ranges up to a breakpoint
    'small-down': { max: 'small' },
    'medium-down': { max: 'medium' },
    'large-down': { max: 'large' },
    'xlarge-down': { max: 'xlarge' },
  },
};
