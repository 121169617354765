import View from '../View';

class DisabledTreeToggle extends View {
  constructor(node) {
    super(node);

    this.treeOverlay = document.querySelector('.tree-overlay-wrapper');

    //When this button is present on the page, that means there is no tree data. If this is the case, and the tree preference option is open, we want to turn it off
    //So that when the user navigates to the next page, the tree wont be open yet.
    if (
      localStorage['treeOpen'] &&
      localStorage['treeOpen'] === 'true' &&
      localStorage['hasClosedTree'] &&
      localStorage['hasClosedTree'] === 'true' &&
      this.treeOverlay !== null
    ) {
      this.treeOverlay.classList.add('hidden');
    }
  }
}

export default DisabledTreeToggle;
