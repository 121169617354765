import View from '../View';
import tinysort from 'tinysort';

class TableSort extends View {
  constructor(node) {
    super(node);

    let defaultSort = this.node.querySelector('.active a')?.parentNode.getAttribute('data-sort');

    if (defaultSort !== null) {
      this.applySorting(defaultSort);
    }

    this.currentSortType = '';
    this.currentOrder = 'desc';
    this.defaultOrder = 'desc';

    let lists = this.node.querySelectorAll('.sort-list');
    lists.addEventListeners('click', e => {
      this.toggleSort(e);
    });
  }

  /**
   * Triggering this function sorts the 'table' based on the link that is clicked on
   *
   * @param e - The event object from the clickhandler defined in this component
   * @return void
   */
  toggleSort = e => {
    //Prevent default browser behaviour
    e.preventDefault();

    //Get the data-sort attribute to know which fields we have to sort in the 'table'
    let sortType = e.target.parentNode.getAttribute('data-sort');

    //If the current sorting type is different than last time, we reset the sorting order and remember the new sorting type
    if (sortType !== this.currentSortType) {
      this.currentSortType = sortType;
      this.currentOrder = this.defaultOrder;
    } else {
      //Flip the current order because the user clicked another time on the same sorting type
      this.currentOrder = this.currentOrder === 'asc' ? 'desc' : 'asc';
    }

    this.applySorting(sortType);
  };

  applySorting = sortType => {
    //Sort the HTML to reflect the sorting type and sorting order
    tinysort('.shortages-list>a', {
      selector: 'ul>li.' + sortType,
      order: this.currentOrder,
      data: 'value',
    });

    //Get all list elements in the element attached to this component
    let listElements = this.node.getElementsByTagName('li');
    let length = listElements.length;

    //Loop over all elements and remove their active and up classes
    for (let i = 0; i < length; i++) {
      listElements[i].classList.remove('active', 'up');

      //If the element is a clickable item to sort the HTML, check also for the current order
      //If not, just check whether the active class should be added to this element
      if (listElements[i].getAttribute('data-sort') === sortType) {
        listElements[i].classList.add('active');

        //Check the current order to see if the up class needs to be added to this element
        if (this.currentOrder === 'asc') {
          listElements[i].classList.add('up');
        }
      } else if (listElements[i].classList.contains(sortType)) {
        listElements[i].classList.add('active');
      }
    }
  };
}

export default TableSort;
