import View from '../View';
import { breakpoint } from '../utils/dom';

class TreeToggle extends View {
  constructor(node) {
    super(node);
    // this.maxContentWidth = 870;
    this.button = this.node;
    this.overlay = document.querySelector('.tree-overlay-wrapper');
    this.button.addEventListener('click', this.toggleTree);
    this.initialArticleWidth = null;
    document.addEventListener('scroll', this.moveTreeOverlay);
    // this event is emitted from index.js (throttled window.onresize)
    window.events.on('resize', this.windowSizeChanged);

    //There can be multiple tree toggles on a page, we only want to open it once.
    //If this is the first time the user is on the kennisbank, we want to open the tree by default (for desktop+), otherwise we use the cookies.
    if (
      (localStorage['treeOpen'] &&
        localStorage['treeOpen'] === 'true' &&
        this.overlay.classList.contains('hidden')) ||
      (localStorage['treeOpen'] === undefined &&
        breakpoint('large-up') &&
        this.overlay.classList.contains('hidden'))
    ) {
      this.toggleTree();
    }
  }

  isOpen = () => {
    return !this.overlay.classList.contains('hidden');
  };

  toggleTree = () => {
    if (!this.overlay) return;
    const isOpen = this.isOpen();
    const allTreeButtons = Array.from(document.querySelectorAll('.js-tree-button'));
    const bodyElement = document.querySelector('body');
    bodyElement.classList.toggle('tree-open');

    if (isOpen) {
      //When it is curently open, we close it and if applicable change back the button text.
      if (allTreeButtons && allTreeButtons.length > 0) {
        for (let i = 0; i < allTreeButtons.length; i++) {
          const button = allTreeButtons[i];
          const treeTextElement = button.querySelector('.tree-text');
          if (treeTextElement !== null) treeTextElement.innerText = 'Inhoud';

          if (button.hasAttribute('has-close-button')) {
            button.classList.remove('showCloseButton');
          }
        }
      }
      this.node.classList.remove('showCloseButton');
      this.overlay.classList.add('hidden');
      bodyElement.classList.remove('no-scroll-mobile');
      //We use the localStorage to show the tree when redirecting to other pages.
      localStorage['treeOpen'] = false;
      if (breakpoint('large-up') && localStorage['hasClosedTree'] === undefined) {
        localStorage['hasClosedTree'] = 'true';
      }

      if (breakpoint('large-down')) {
        const originialPosition = bodyElement.style.getPropertyValue('--scroll-offset');
        window.scrollTo(0, originialPosition);
      }

      const articleContent = document.querySelector('.article-main-content');
      if (articleContent) {
        articleContent.removeAttribute('style');
      }
      this.initialArticleWidth = null;

      return;
    }

    //When the tree is currently closed, we open it and change the button text if applicable.
    //If the screen si bigger than 850px, we also want to set the height of the overlay to make it as tall as the document.
    if (allTreeButtons && allTreeButtons.length > 0) {
      for (let i = 0; i < allTreeButtons.length; i++) {
        const button = allTreeButtons[i];
        const treeTextElement = button.querySelector('.tree-text');
        if (treeTextElement !== null) treeTextElement.innerText = 'Sluit';

        if (button.hasAttribute('has-close-button')) {
          button.classList.add('showCloseButton');
        }
      }
    }
    const scrollDistance = window.pageYOffset;
    this.overlay.classList.remove('hidden');
    //We store the original scroll position as a css custom variable in the body, which we can later access to scroll towards (if on mobile).
    bodyElement.style.setProperty('--scroll-offset', scrollDistance);
    bodyElement.classList.add('no-scroll-mobile');
    //We use the localStorage to show the tree when redirecting to other pages.
    localStorage['treeOpen'] = true;

    this.adjustArticleSize();
  };

  windowSizeChanged = () => {
    if (this.overlay && this.isOpen()) {
      this.adjustArticleSize();
    }
  };

  adjustArticleSize = () => {
    if (breakpoint('new-header-search-up')) {
      const articleContent = document.querySelector('.article-main-content');
      if (articleContent !== null && this.overlay !== null) {
        //This will be occupied space on the right side of the screen.
        const treeWidth = this.overlay.getBoundingClientRect().width;
        const articleContentBounds = articleContent.getBoundingClientRect();
        if (this.initialArticleWidth == null) {
          this.initialArticleWidth = articleContentBounds.width;
        }

        const articleRightMost = articleContentBounds.x + this.initialArticleWidth;
        //How much the tree is over the content.
        const overlap = window.innerWidth - treeWidth - articleRightMost;

        // if (overlap < 0) {
        //   //The current width - how much of the tree is on top and a small buffer
        //   const maxContentWidth = this.initialArticleWidth + overlap - 30;
        //   if(maxContentWidth < this.maxContentWidth) {
        //     articleContent.style.maxWidth = `${maxContentWidth}px`;
        //   } else {
        //     articleContent.style.maxWidth = `${this.maxContentWidth}px`;
        //   }
        // } else {
        //   articleContent.style.maxWidth = `${this.maxContentWidth}px`;
        // }
      }
    }
  };

  //This functino will move the tree overlay up when scrolling down, so that it connects nicely with the header/subheader even when scrolling.
  moveTreeOverlay = () => {
    if (breakpoint('large-down')) return;
    const subHeader = document.querySelector('.sub-header');
    const header = document.querySelector('header');
    if (!subHeader || !header) return;
    const subHeaderHeight = subHeader.getBoundingClientRect().height;
    const headerHeight = header.getBoundingClientRect().height;

    const totalHeight = subHeaderHeight + headerHeight;
    const pageOffset = window.pageYOffset;

    if (pageOffset > totalHeight && !this.overlay.classList.contains('header-is-sticky')) {
      this.overlay.classList.add('header-is-sticky');
    } else if (pageOffset < totalHeight && this.overlay.classList.contains('header-is-sticky')) {
      this.overlay.classList.remove('header-is-sticky');
    }
  };
}

export default TreeToggle;
