// import 'core-js/fn/array/from';
require('formdata-polyfill');
import './utils/Element';
import './utils/HTMLCollection';
import './utils/LocalStorage';

import settings from './settings';
import mitt from 'mitt';
import throttle from 'lodash/throttle';
import View from './View';
import React, { Component } from 'react';
import { render } from 'react-dom';

function init() {
  setupEvents();
  initComponents();
}

function initComponents() {
  settings.forEach(setting => {
    Array.from(document.querySelectorAll(setting.selector)).forEach(node => {
      if (setting.isReact) {
        render(<setting.component />, node);
      } else {
        try {
          new setting.component(node);
        } catch (error) {
          console.error("Failed to initalize component for ", setting.selector, node, error);
        }
      }
    });
  });
}

function setupEvents() {
  window.events = mitt();
  window.addEventListener('resize', handleResize);
}

const handleResize = throttle(() => {
  events.emit('resize');
}, 100);

init();
