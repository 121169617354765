import View from '../View';

class AttachedSearchFilters extends View {
  constructor(node) {
    super(node);

    // Add event listener to toggle options container
    this.node.addEventListener('click', e => this.toggleOptionsContainer(e));

    this.textNode = this.node.querySelector('.selected-filter-text');
    this.searchBar = this.node.parentNode.querySelector('.search-input--withFilters');
    // this.hiddenInputs = Array.from(this.node.parentNode.querySelectorAll('.hidden-input'));
    this.hiddenInput = this.node.parentNode.querySelector('.hidden-input');

    this.options = Array.from(this.node.querySelectorAll('.option'));

    if (Array.isArray(this.options) && this.options.length > 0) {
      this.options.forEach(option => {
        option.addEventListener('click', e => this.changeSelectedFilter(e));
      });
    }

    this.setDefaultValue();
  }

  toggleOptionsContainer = (e) => {
    e.preventDefault();

    if (this.node.classList.contains('active')) {
      this.node.classList.remove("active");
      return;
    }
    this.node.classList.add("active");
  }

  setDefaultValue = () => {
    //If we are not a detail page, we want to change the default value based on the tab preference, otherwise we always default to the current domain
    if (this.textNode.classList.contains('no-domain')) {
      const homepageSearchTabPreference = localStorage.getItem('homepageSearchTab');
      if (homepageSearchTabPreference === 'im') {
        this.setOptionAsActive('Informatorium Medicamentorum');
        this.textNode.innerText = 'Informatorium Medicamentorum';
        this.searchBar.placeholder = 'Zoeken in Informatorium Medicamentorum';
        this.searchBar.dataset.source = 'Informatorium_Medicamentorum';
      } else {
        this.setOptionAsActive('');
        this.textNode.innerText = 'Gehele Kennisbank Informatorium Medicamentorum';
        this.searchBar.placeholder = 'Zoeken in Gehele Kennisbank';
        // We want to disable the hidden inputs when the "gehele kennisbank" is chosen, so that they aren't used as filters.
        this.disableHiddenInput();
      }
    } else {
        if (this.textNode.innerText === 'Informatorium Medicamentorum') {
          this.setOptionAsActive('Informatorium Medicamentorum');
        } else {
          this.setOptionAsActive('');
        }
    }
  };

  setOptionAsActive = (value) => {
      // Set active class on option and remove others
      if (Array.isArray(this.options) && this.options.length > 0) {
        this.options.forEach(option => {
          if (option.value === value) {
            option.classList.add("active");
          } else {
            option.classList.remove("active");
          }
        });
      }

  }

  changeSelectedFilter = e => {
    e.preventDefault();

    //If the whole kennisbank is chosen, we disabled all hidden inputs so they are not used as filters
    if (e.target.value === '') {
      this.disableHiddenInput();
    } else {
      this.enableHiddenInput();
    }

    //The filters 'name' is the value of the button pressed, except for when pressing 'Gehele kennisbank', because then we don't send along a filter (so there is no value)
    const filterName = e.target.value || 'Gehele Kennisbank';
    //The value that we get here is with underscores, but because we are only indicating the source we are filtering on, we remove the underscores here.
    const finalFilterName = filterName.replace(/_/g, ' ');

    this.textNode.innerText = finalFilterName;
    this.searchBar.placeholder = `Zoeken in ${finalFilterName}`;

    this.setOptionAsActive(e.target.value);

    return;
  };

  disableHiddenInput = () => {
    if (this.hiddenInput !== null) {
      this.hiddenInput.setAttribute('disabled', '');
    }
    if (this.searchBar !== null) {
      this.searchBar.removeAttribute('data-source');
    }
    return;
  };

  enableHiddenInput = () => {
    if (this.hiddenInput !== null) {
      this.hiddenInput.removeAttribute('disabled');
    }
    if (this.searchBar !== null) {
      const hiddenInputValue = this.hiddenInput.value;

      //Value will be: source[name], so we want to remove source[ and later the ]
      let sourceString = hiddenInputValue.substring(7, hiddenInputValue.length);
      sourceString = sourceString.substring(0, sourceString.length - 1);

      this.searchBar.setAttribute('data-source', sourceString);
    }
    return;
  };
}

export default AttachedSearchFilters;
