import View from '../View';
import { gsap, Power3 } from 'gsap';

class Header extends View {
  constructor(node) {
    super(node);

    this.body = document.querySelector('body');
    this.menuToggle = this.node.querySelector('.menu-toggle');
    this.headerMenu = this.node.querySelector('.header-menu');
    this.accountButton = this.node.querySelector('.account-button');
    this.accountMenu = this.node.querySelector('.account-menu');
    this.searchIconButton = this.node.querySelector('.header-search-button');
    this.searchForm = this.node.querySelector('.search-form');
    this.isOpen = false;
    this.treeOverlay = document.querySelector('.tree-overlay-wrapper');
    this.treeListWrapper = document.querySelector('.tree-list-wrapper');
    this.shadowHeader = document.querySelector('.shadow-header');
    this.shadowSubHeader = document.querySelector('.shadow-sub-header');

    this.subHeader = document.querySelector('nav.sub-header');

    window.addEventListener('click', this.handleOutsideSearchClick);
    window.addEventListener('scroll', this.checkIfShouldBesticky);

    // Call for initial sidebar height
    this.checkIfShouldBesticky();

    if (this.menuToggle !== null) {
      this.menuToggle.addEventListener('click', this.toggleMenu);
    }

    if (this.accountButton !== null) {
      this.accountButton.addEventListener('click', this.toggleAccountMenu);
    }
    if (this.searchIconButton !== null) {
      this.searchIconButton.addEventListener('click', this.handleSearchIconClick);
    }
  }

  dispose() {
    window.removeEventListener('click', this.handleOutsideSearchClick);
    window.removeEventListener('scroll', this.checkIfShouldBesticky);
  }

  checkIfShouldBesticky = () => {
    const headerHeight = this.node.getBoundingClientRect().height;

    let subHeaderheight = 0;
    if (this.subHeader) {
      subHeaderheight = this.subHeader.getBoundingClientRect().height;
    }
    const totalHeight = subHeaderheight + headerHeight;
    const pageOffset = window.pageYOffset;
    const treeOverlayTop = headerHeight + subHeaderheight - window.pageYOffset;

    if (pageOffset > totalHeight && !this.node.classList.contains('sticky')) {
      this.makeHeaderSticky(headerHeight, this.shadowHeader, subHeaderheight, this.shadowSubHeader);
    } else if (pageOffset < totalHeight && this.node.classList.contains('sticky')) {
      this.makeHeaderUnsticky(this.shadowHeader, this.shadowSubHeader, treeOverlayTop);
    }

    if (!this.node.classList.contains('sticky') && pageOffset < totalHeight && this.treeOverlay) {
      this.treeOverlay.classList.remove('stickyHeader');
      this.treeOverlay.style.top = `${treeOverlayTop}px`;
      this.treeOverlay.style.height = `${window.innerHeight - treeOverlayTop}px`;
    }

    if (this.treeListWrapper) {
      this.treeListWrapper.style.height = `${window.innerHeight - this.treeOverlay.getBoundingClientRect().top}px`;
    }
  };

  makeHeaderSticky(headerHeight, shadowHeader, subHeaderheight, shadowSubHeader) {
    events.emit('stickyHeaderToggle');

    this.node.classList.add('sticky');
    shadowHeader.style.height = `${headerHeight}px`;
    if (this.subHeader && shadowSubHeader) {
      this.subHeader.classList.add('sticky');
      shadowSubHeader.style.height = `${subHeaderheight}px`;
    }
    if (this.treeOverlay) {
      this.treeOverlay.classList.add('stickyHeader');
      this.treeOverlay.style.top = null;
      this.treeOverlay.style.height = null;
      // this.treeListWrapper.style.height = null;
    }
  }

  makeHeaderUnsticky(shadowHeader, shadowSubHeader, treeOverlayTop) {
    events.emit('stickyHeaderToggle');
    this.node.classList.remove('sticky');
    shadowHeader.style.height = '0px';
    if (this.subHeader && shadowSubHeader) {
      this.subHeader.classList.remove('sticky');
      shadowSubHeader.style.height = `0pc`;
    }
    if (this.treeOverlay) {
      this.treeOverlay.classList.remove('stickyHeader');
      this.treeOverlay.style.top = `${treeOverlayTop}px`;
    }
  }

  handleOutsideSearchClick = e => {
    const innerWidth = window.innerWidth;
    const bodyElement = document.querySelector('body');
    const isHomePage = bodyElement.classList.contains('page-home');
    //On the homepage, the searchbar is always collapsed on 850+ so we want to check, on other pages we only want to check between 850-1400;
    const shouldCheck =
      (isHomePage && innerWidth >= 850 && this.isOpen) ||
      (!isHomePage && innerWidth >= 850 && innerWidth <= 1399 && this.isOpen);
    if (shouldCheck) {
      if (e.target.closest('.search-form') === null) {
        this.searchIconButton.classList.toggle('hide');
        this.searchForm.classList.toggle('active');
        this.isOpen = false;
      }
    }
  };

  handleSearchIconClick = e => {
    //We need to toggle the class on the icon that is visible on 850px+. That is always the 2nd button in the DOM.
    this.searchIconButton.classList.toggle('hide');
    this.searchForm.classList.toggle('active');
    //This timeout prevents the search from being closed by detectOutsideClick
    setTimeout(() => {
      this.isOpen = !this.isOpen;
    }, 10);
  };

  toggleMenu = e => {
    e.preventDefault();
    if (this.menuToggle.classList.contains('active')) {
      this.hideMenu();
    } else {
      this.showMenu();
    }
  };

  hideMenu = () => {
    this.body.classList.remove('no-scroll');

    this.menuToggle.classList.remove('active');
    this.node.classList.remove('menu-open');

    const tl = gsap.timeline();

    tl.to(this.headerMenu, {
      duration: 0.2,
      opacity: 0,
    }).set(this.headerMenu, {
      visibility: 'hidden',
    });
  };

  showMenu = () => {
    this.body.classList.add('no-scroll');

    this.menuToggle.classList.add('active');
    this.node.classList.add('menu-open');

    const tl = gsap.timeline();

    tl.set(this.headerMenu, {
      visibility: 'visible',
    })
      .to(this.headerMenu, {
        duration: 0.3,
        opacity: 1,
      })
      .from(
        this.headerMenu,
        {
          duration: 0.4,
          top: -50,
          ease: Power3.easeOut,
        },
        '-=.3'
      );
  };

  toggleAccountMenu = e => {
    e.preventDefault();
    this.accountMenu.classList.toggle('active');
  };
}

export default Header;
