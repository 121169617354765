import View from '../View';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

class Tree extends View {
  currentClickedListItem = {};

  constructor(node) {
    super(node);

    this.node = this.node.querySelector('.tree-list-wrapper');
    this.main = document.getElementById('article-detail');

    // set defaults
    this.currentParent = this.node;
    this.currentClickedListItem = {};

    // initialize the tree
    if (this.main !== null) {
      this.initializeTree();
    }

    // add an event listener
    this.node.addEventListener('click', e => {

      // reset to empty on click
      this.currentClickedListItem = {};

      // remove current item class if available.
      const current = this.node.querySelector('a.current-item');
      if (current !== null) {
        current.classList.remove('current-item');
        current.parentNode.classList.remove('current-item');
      }

      const expandOnly = e.target.classList.contains('expand-folder');

      // only react to the click if an anchor tag is clicked with the folder class
      if (expandOnly || (e.target.tagName === 'A' && e.target.classList.contains('folder'))) {
        // if we hit a folder, don't open the href
        e.preventDefault();

        // set variables for use later on
        this.currentClickedListItem = e.target.closest('a');
        this.currentParent = this.currentClickedListItem.parentNode;

        const closeFolder = () => {
          this.animateFolderContentsOut(this.currentParent.querySelector('ul'));
          this.currentParent.querySelector('ul').classList.remove('open');
          this.currentClickedListItem.classList.remove('open');
        };

        const openFolder = () => {
          this.animateFolderContentsIn(this.currentParent.querySelector('ul'));
          this.currentParent.querySelector('ul').classList.add('open');
          this.currentClickedListItem.classList.add('open');
        };

        // if there is an ul element, just change the open state and be done with it. No need for data loading
        if (this.currentClickedListItem.parentNode.querySelector('ul') !== null) {
          const isOpened = this.currentParent.querySelector('ul').classList.contains('open');
          if (expandOnly) {
            // user only wants to expand/collapse folder
            if (isOpened) {
              closeFolder();
            } else {
              openFolder();
            }
          } else {
            // user wants to open the page
            if (this.openPage(this.currentClickedListItem.parentNode)) {
              // Not even the same page, no need to update the tree.
              return;
            }
            if (!isOpened) {
              // was not opened yet, so open it
              openFolder();
            }
          }
        } else {
          // no ul element, so load the data from the api
          this.currentClickedListItem.classList.add('loading');
          this.getTree(
            this.currentParent.dataset.domainkey,
            this.currentParent.dataset.sourcekey,
            this.currentParent.dataset.folderkey,
            this.currentParent.dataset.treedepth,
            expandOnly
          );
        }
      } else {
        if (location.pathname === e.target.pathname) {
          e.target.classList.add('current-item');
          e.target.parentNode.classList.add('current-item');
        }
      }
    });
  }

  /**
   *
   */
  initializeTree = () => {
    // get the initial tree structure, based on the loaded item
    this.getInitialTree();
  };

  /**
   * Get the tree data to show on the initial load of the page
   * @returns {Promise.<void>}
   */
  getInitialTree = async () => {
    // set initial values
    let domainKey = this.node.dataset.domainkey;
    let sourceKey = this.node.dataset.sourcekey;
    let folderKey = this.node.dataset.folderkey;
    let currentPath = window.location.pathname;

    const url = this.getInitialTreeUrl(domainKey, sourceKey, folderKey, currentPath);

    this.fetchAsync(url, { method: 'GET', credentials: 'same-origin' }).then(data => {
      this.renderTree(data.data, true);
      //locate the currently active item
      let currentItem = this.node.querySelector('li.current-item');

      if (!currentItem) {
        events.emit('treeLoaded');
        return;
      }

      this.setCurrentItemAfterPageLoad(currentItem).then(() => {
        events.emit('treeLoaded');
      })

    });
  };

  /**
   * Open tree (in depth) and set current item
   *
   * @param currentItem
   */
  openCurrentItemAfterPageLoad = (currentItem) => {
      // reset to empty on click
      this.currentClickedListItem = {};

      // remove current item class if available.
      const current = this.node.querySelector('a.current-item');
      if (current !== null) {
        current.classList.remove('current-item');
        current.parentNode.classList.remove('current-item');
      }

      const expandOnly = currentItem.classList.contains('expand-folder');

      // only if i haves the folder class
      if (expandOnly || (currentItem.tagName === 'A' && currentItem.classList.contains('folder'))) {

        // set variables for use later on
        this.currentClickedListItem = currentItem.closest('a');
        this.currentParent = this.currentClickedListItem.parentNode;

        // load the sub tree
        this.currentClickedListItem.classList.add('loading');
        this.getTree(
          this.currentParent.dataset.domainkey,
          this.currentParent.dataset.sourcekey,
          this.currentParent.dataset.folderkey,
          this.currentParent.dataset.treedepth,
          expandOnly,
          true
        );
      }
  }

  /**
   * Set current item after (hard) page load when hash (id) isset
   *
   * @param node
   */
  setCurrentItemAfterPageLoad = async (currentItem) => {
    // Check if hash isset if not scroll to current (top) item
    if (!location.hash) {
      this.scrollToTreeItem(currentItem);
      return;
    }

    // Get the element by subpage id
    const subpageID = location.hash.slice(1);
    const subpageElement = document.getElementById(subpageID);

    const subpageTreePath = subpageElement.getAttribute("tree_path");

    const subpagePathSteps = subpageTreePath.split("/")

    let pathStepIterator = 0;
    let pathStepString = "";
    let pathStepContainer = [];

    // Loop throug tree steps to build tree paths
    for (const subpagePathStep of subpagePathSteps) {
      if (pathStepIterator === 0) {
        pathStepString = subpagePathStep;
        pathStepContainer.push(pathStepString)
        pathStepIterator++;
        continue;
      }

      pathStepContainer.push(pathStepString += "/" + subpagePathStep)
      pathStepIterator++;
    }

    pathStepIterator = 0;

    // Loop throug path steps open them
    for (const pathStepID of pathStepContainer) {
      if (pathStepIterator === 0) {
        pathStepIterator++;
        continue;
      }

      // Wait for the element is loaded
      await this.waitForElement(this.node, `#${CSS.escape(pathStepID)}`);

      // Temporary force click to test
      this.openCurrentItemAfterPageLoad(this.node.querySelector(`#${CSS.escape(pathStepID)}`));
      pathStepIterator++;
    }

    return;
  }

  /**
   * Wait for a element exists in the tree (observer)
   *
   * @param node
   * @param selector
   */
  waitForElement = (node, selector) => {
    return new Promise(resolve => {
        if (node.querySelector(selector)) {
            return resolve(node.querySelector(selector));
        }

        const observer = new MutationObserver(mutations => {
            if (node.querySelector(selector)) {
                observer.disconnect();
                resolve(node.querySelector(selector));
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
  }

  /**
   * Return the fetch url to get the tree-level from the API
   * @param domainKey
   * @param sourceKey
   * @param folderKey
   * @param currentPath
   */
  getInitialTreeUrl = (domainKey, sourceKey, folderKey, currentPath) => {
    const query = createUrlQuery({
      domainKey,
      sourceKey,
      folderKey,
      currentPath,
    });

    return `/article/initial-tree${query}`;
  };

  /**
   * Opens the page specified by js-open-page
   * @param {*} parentNode node to look inside
   * @returns true if navigating away from current page
   */
  openPage = (parentNode, skipLocationChange = false) => {
    const openPage = parentNode.querySelector('.js-open-page');

    if (openPage !== null) {
      // Page to open found.
      if (location.href !== openPage.href) {
        // Different href, move to new url.
        if (!skipLocationChange) {
          location.href = openPage.href;
        }
        if (location.pathname !== openPage.pathname) {
          // Not even the same page, no need to update the tree.
          return true;
        }
      }
      if (location.pathname === openPage.pathname) {
        // Add current-item class.
        openPage.classList.add('current-item');
        openPage.parentNode.classList.add('current-item');
        this.scrollToTreeItem(openPage.parentNode);
      }
    }

    return false;
  };

  /**
   * Return the fetch url to get the tree-level from the API
   * @param domainKey
   * @param sourceKey
   * @param folderKey
   * @param treeDepth
   */
  getTreeUrl = (domainKey, sourceKey, folderKey, treeDepth) => {
    folderKey = encodeURIComponent(folderKey);
    return `/article/tree?domainKey=${encodeURI(
      domainKey
    )}&sourceKey=${sourceKey}&folderKey=${folderKey}&treeDepth=${treeDepth}`;
  };

  /**
   * Do an asynchronous call to the api
   * @param url
   * @param request
   * @param opts
   * @returns {Promise.<{data: *}>}
   */
  fetchAsync = async (url, request, opts = {}) => {
    // await response of fetch call
    let response = await fetch(url, request);
    // only proceed once promise is resolved
    let data = await response.json();
    // only proceed once second promise is resolved
    return { data, ...opts };
  };

  /**
   * Get the tree structure based on the item clicked.
   * @param domainKey
   * @param sourceKey
   * @param folderKey
   * @param treeDepth
   * @param expandOnly Do not open the new page, only expand its children
   */
  getTree = (domainKey, sourceKey, folderKey, treeDepth, expandOnly, skipLocationChange) => {
    const url = this.getTreeUrl(domainKey, sourceKey, folderKey, treeDepth);
    this.fetchAsync(url, { method: 'GET', credentials: 'same-origin' }, { treeDepth, folderKey })
      .then(data => {
        this.renderTree(data.data, false, expandOnly, skipLocationChange);
      })
      .catch(e => {
        console.log(e);
      });
  };

  /**
   * Render the tree in the correct place (based on clicked element)
   * @param tree
   * @param initial if this is the initial call
   * @param expandOnly if true, do not open page, only expand children
   */
  renderTree = (tree, initial, expandOnly, skipLocationChange) => {
    this.currentParent.innerHTML += '<ul class="tree-list plain"></ul>';
    this.currentUl = this.currentParent.querySelector('ul');

    this.currentUl.innerHTML += tree;

    //fade tree in when loaded (animation in css)
    this.node.parentNode.classList.remove('hidden');

    /*
     * show the contents of the folder
     * add the open classes
     * remove the loading class
     * -- all if applicable
     */
    if (this.currentParent.querySelector('a') !== Object) {
      if (initial === false && !expandOnly) {
        // Only look for the page to open if its not the initial call and we're not only expanding.
        if (this.openPage(this.currentParent, skipLocationChange)) {
          // Not even the same page, no need to update the tree.
          return;
        }
      }
      if (this.currentParent.querySelector('ul') !== null) {
        this.animateFolderContentsIn(this.currentParent.querySelector('ul'));
        this.currentParent.querySelector('ul').classList.add('open');
      }
      if (this.currentParent.querySelector('a') !== null) {
        this.currentParent.querySelector('a').classList.add('open');
        this.currentParent.querySelector('a').classList.remove('loading');
      }
    }
  };

  /**
   * Show the contents of the clicked folder (ul element)
   * @param element
   */
  animateFolderContentsIn = element => {
    if (!element) return;
    gsap.set(element, { height: 'auto' });
    gsap.from(element, { duration: 0.5, height: 0 });
  };

  /**
   * Hide the contents of the clicked folder (ul element)
   * @param element
   */
  animateFolderContentsOut = element => {
    if (!element) return;
    gsap.to(element, { duration: 0.5, minHeight: 0, height: 0 });
  };

  /**
   * Scroll to an element by using GSAP.
   * @param element
   */
  scrollToTreeItem = element => {
    // THis distance is to the nearest parent element with a position, which is teh treeWrapper in this case.
    // The element will get centered vertically in the tree.
    let offsetFromNode = element.offsetTop;
    let elementHeight = element?.getBoundingClientRect()?.height || 0;

    let treeOverlayWrapper = document.querySelector('.tree-overlay-wrapper');
    let overlayHeight = treeOverlayWrapper?.getBoundingClientRect()?.height || window.innerHeight;

    let offset = overlayHeight / 2 - elementHeight / 2;
    let top = offsetFromNode - offset;
    gsap.set(this.node, { scrollTo: top });
  };
}

function createUrlQuery(params = {}) {
  const esc = encodeURIComponent;
  const joined = Object.keys(params)
    .map(k => `${esc(k)}=${esc(params[k])}`)
    .join('&');
  return `?${joined}`;
}

export default Tree;
