import View from '../View';
import { isTargetWithin } from '../utils/dom';
import { gsap, Power3 } from 'gsap';

class Expandable extends View {
  constructor(node) {
    super(node);

    this.expandableElement = this.node;
    this.expandableButtons = this.node.querySelector('.expandable-header');
    this.expandableIgnore = this.expandableButtons.querySelector('.expandable-header-ignore');
    this.expandableContent = this.node.querySelector('.expandable-content');

    this.expandableButtons.addEventListener('click', this.handleClick);
  }

  handleClick = e => {
    if (this.expandableIgnore && isTargetWithin(e.target, this.expandableIgnore)) return;
    //Extra check to make sure that it's really the expandable is being clicked and not a filter checkbox.
    if (
      !e.target.classList.contains('close-button') &&
      !e.target.classList.contains('edit-button') &&
      !e.target.classList.contains('info-popup-icon') &&
      !e.target.classList.contains('filter') &&
      e.target.closest('.custom-checkbox') === null &&
      e.target.className !== 'check'
    ) {
      if (!this.expandableElement.classList.contains('open')) {
        this.open(this.expandableElement);
      } else {
        this.close(this.expandableElement);
      }
    }
  };

  open = () => {
    let tl = gsap.timeline({ onComplete: this.refreshWaypoints });
    tl.fromTo(this.expandableContent, 
      {
        height: 0 
      },
      {
        duration: 0.7,
        height: "auto",
        ease: Power3.easeInOut,
    });
    this.expandableElement.classList.add('open');
  };

  close = () => {
    let tl = gsap.timeline({ onComplete: this.refreshWaypoints });
    tl.to(this.expandableContent, {
      duration: 0.7,
      height: 0,
      ease: Power3.easeInOut,
    });

    this.expandableElement.classList.remove('open');
  };

  refreshWaypoints = () => {
    Waypoint.refreshAll();
  };
}

export default Expandable;
