import View from '../../View';

class SearchResultTools extends View {
  constructor(node) {
    super(node);

    this.printButton = this.node.querySelector('.print');
    if (this.printButton) {
      this.printButton.addEventListener('click', this.printClickHandler);
    }
  }

  /**
   * Handler for print functionality
   *
   * @param e
   */
  printClickHandler = e => {
    e.preventDefault();

    let width = 800;
    let height = 600;

    let left = screen.width / 2 - width / 2;
    let top = screen.height / 2 - height / 2;

    window.open(
      '/search/print',
      'printWindow',
      'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, top=' +
        top +
        ', left=' +
        left +
        ', height=' +
        height +
        ',width=' +
        width
    );
  };
}

export default SearchResultTools;
