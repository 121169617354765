import View from '../View';

class BackButton extends View {
  constructor(node) {
    super(node);
    this.button = this.node;
    this.button.addEventListener('click', this.goBackFunction);

    if (!window.history || window.history.length <= 2) {
      this.button.classList.add('hidden');
    }
  }

  goBackFunction = () => {
    if (window.history.length > 1) {
      window.history.back();
    }
  };
}

export default BackButton;
