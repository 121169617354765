import View from '../View';
import { breakpoint } from '../utils/dom';

class Layout extends View {
  constructor(node) {
    super(node);

    events.on('pageLoaded', this.handlePageLoaded);
    this.makeExternalLinksOpenInNewTab();
  }

  handlePageLoaded = () => {
    document.querySelector('#content').classList.remove('loading');
  };

  /**
   * Give external links (with http(s)) a target="_blank"
   */
  makeExternalLinksOpenInNewTab = () => {
    let anchors = document.querySelector('#content').querySelectorAll("a[href^='http']");

    Object.keys(anchors).forEach(key => {
      if (
        (anchors[key].href.indexOf('kennisbank') === -1 &&
          anchors[key].href.indexOf('knmp.local') === -1) ||
        anchors[key].href.indexOf('files') !== -1
      ) {
        anchors[key].target = '_blank';
      }
    });
  };
}

export default Layout;
