import View from '../View';

class ContentFromSource extends View {
  constructor(node) {
    super(node);

    this.patchURLs();
  }

  patchURLs = () => {
    // Patch urls.

    // Add 'file' to the url's of pdf's and doc's where the url is relative from the root.
    // Except for urls starting with /files allready.
    let anchors = this.node.querySelectorAll(
      'a[href$=".pdf"], a[href$=".doc"], a[href$=".PDF"], a[href$=".DOC"]'
    );
    for (let i = 0; i < anchors.length; i++) {
      let attrib = anchors[i].getAttribute('href');
      if (attrib[0] === '/' && attrib.indexOf('/files') !== 0) {
        anchors[i].href = '/files' + attrib;
      }
    }
    anchors = this.node.querySelectorAll('img[src]');
    for (let i = 0; i < anchors.length; i++) {
      let attrib = anchors[i].getAttribute('src');
      if (attrib && attrib[0] === '/' && attrib.indexOf('/files') !== 0) {
        anchors[i].src = '/files' + attrib;
      }
    }

    // Add 'article' to the url's of html files and area tags where the url is relative from the root.
    // Except for urls starting with /kennisbank and /article.
    anchors = this.node.querySelectorAll('a[href*=".html"], area[href]');
    for (let i = 0; i < anchors.length; i++) {
      const attrib = anchors[i].getAttribute('href');
      if (
        attrib[0] === '/' &&
        attrib.indexOf('/general') !== 0 &&
        attrib.indexOf('/article') !== 0
      ) {
        anchors[i].href = '/article' + attrib;
      }
      if (attrib.indexOf('#') !== -1) {
        events.emit('add-same-page-anchor-listener', anchors[i]);
      }
    }
  };
}

export default ContentFromSource;
