import View from '../View';

class ArticleHeaderImage extends View {
  constructor(node) {
    super(node);

    this.headerImage = this.node;
    this.headerImagePopup = document.querySelector('.header-image-popup');
    this.headerImageWrapper = this.headerImagePopup.querySelector('.header-image-wrapper');

    this.moveHeaderImage();
  }

  moveHeaderImage = () => {
    this.headerImagePopup.classList.remove('hidden');
    this.headerImageWrapper.appendChild(this.headerImage);

    this.headerImagePopup.addEventListener('click', e => {
      this.headerImagePopup.classList.toggle('open');
    });
  };
}

export default ArticleHeaderImage;
