import View from '../View';
import { tocRemovedEvent } from "./toc";

const relatedDocumentsSelector = ".js-related-documents";
const relatedDocumentsContainerSelector = ".related-documents-container";

class RelatedDocuments extends View {
  constructor(node) {
    super(node);

    this.source = node.dataset.source;
    this.linkOn = node.dataset.linkOn;
    const expandMaximum = node.dataset.expandMaximum ? node.dataset.expandMaximum : 5;

    // use setTimeout to defer execution
    setTimeout(() => {
      const linkOnData = this.getLinkOnData();
      if (!linkOnData) {
        return;
      }
      const searchTerm = this.buildQuery(linkOnData);

      const formData = new FormData();
      formData.append('searchTerm', searchTerm);

      fetch("/api/relatedDocuments", {
        headers: new Headers(),
        method: 'POST',
        body: formData,
        credentials: 'same-origin',
      }).then(response => {
        return response.json()
      }).then(receivedData => {
        if (receivedData && receivedData.count > 0) {
          if (receivedData.count <= expandMaximum) {
            this.expandExpandable();
          }
          for (const article of receivedData.articles) {
            this.appendLink("/" + article.url, article.title);
          }
        } else {
          // no related documents found
          this.removeSection();
        }
      });
    }, 0);
  }

  expandExpandable() {
    const expandableParent = this.getExpandableParent();
    if (expandableParent) {
      expandableParent.classList.add("open");
    }
  }

  buildQuery(linkOnData) {
    let searchTerm = `${this.linkOn}:(`;
    searchTerm += linkOnData.join(" OR ");
    searchTerm += ")";

    if (this.source) {
      searchTerm += ` AND source:${this.source}`;
    }

    return searchTerm;
  }

  appendLink(url, title) {
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.innerText = title;
    anchor.target = "_blank";
    this.node.appendChild(anchor);

    const linebreak = document.createElement("br");
    this.node.appendChild(linebreak);
  }

  getLinkOnData() {
    const metadataList = document.getElementById(`metadata-${this.linkOn}`);
    if (metadataList) {
      return JSON.parse(metadataList.innerHTML);
    }
    return null;
  }

  getExpandableParent() {
    if (this.node.classList.contains("expandable-content")) {
      const expandableParent = this.node.closest(".expandable");
      return expandableParent;
    }
    return null;
  }

  removeSection() {
    const relatedDocumentsRoot = this.node.closest(relatedDocumentsContainerSelector);
    const relatedDocuments = relatedDocumentsRoot.querySelectorAll(relatedDocumentsSelector);

    if (relatedDocuments.length == 1) {
      // remove entire section as this is the last subsection
      this.cleanRemoveNode(relatedDocumentsRoot);
    } else {
      // remove just this section
      let node = this.node;
      const expandableParent = this.getExpandableParent();
      if (expandableParent) {
        node = expandableParent;
      }
      this.cleanRemoveNode(node);
    }
  }

  /* Removes the node from the DOM while also notifying the TOC component of any changes */
  cleanRemoveNode(node) {
    const tocEntries = node.querySelectorAll("[data-toc]");
    const event = new Event(tocRemovedEvent, {bubbles: true});
    for(const entry of tocEntries) {
      entry.dispatchEvent(event);
    }

    node.remove();
  }
}

export default RelatedDocuments;
export { relatedDocumentsSelector };
