import View from '../View';

class RecentMessage extends View {
  constructor(node) {
    super(node);

    this.closeButton = this.node.querySelector('.close-message-button');
    this.navigationDots = Array.from(this.node.querySelectorAll('.clickable-message-dot'));
    this.navigationArrows = Array.from(this.node.querySelectorAll('.message-arrow'));

    if (this.closeButton !== null) {
      this.closeButton.addEventListener('click', this.hideMessage);
    }

    if (Array.isArray(this.navigationDots) && this.navigationDots.length > 0) {
      this.navigationDots.forEach(dot => dot.addEventListener('click', e => this.showOtherCard(e)));
    }
    if (Array.isArray(this.navigationArrows) && this.navigationArrows.length > 0) {
      this.navigationArrows.forEach(arrow =>
        arrow.addEventListener('click', e => this.handleArrowClick(e))
      );
    }
  }

  hideMessage = e => {
    e.preventDefault();
    let date = null;
    const highestDateCard = this.getHighestDateCard();
    if (highestDateCard !== null) {
      date = highestDateCard.querySelector('.date');
    }
    if (date !== null) {
      document.cookie =
        'hideMessagesUntil=' + date.innerHTML + '; expires=Wed, 01 Jan 2108 12:00:00 UTC; SameSite=Lax';
    }
    this.node.parentNode.classList.add('hidden');
  };

  getHighestDateCard = () => {
    const highestCard = this.node.parentNode.querySelector('.highest-date');
    return highestCard;
  };

  showOtherCard = (e, index = null) => {
    const indexToGoTo = index !== null ? index : e.target.dataset.index;
    if (indexToGoTo !== null) {
      const allMessageCards = Array.from(
        this.node.parentNode.querySelectorAll('.js-recent-message')
      );
      if (Array.isArray(allMessageCards) && allMessageCards[indexToGoTo] !== undefined) {
        const cardToShow = allMessageCards[indexToGoTo];
        cardToShow.classList.remove('hide');
        this.node.classList.add('hide');
      }
    }
  };

  handleArrowClick = e => {
    let indexToGoTo = 0;
    if (e.target.classList.contains('fake-chevron')) {
      indexToGoTo = e.target.parentNode.dataset.index;
    } else {
      indexToGoTo = e.target.dataset.index;
    }
    this.showOtherCard(e, indexToGoTo);
  };
}

export default RecentMessage;
