import View from '../View';
import { gsap, Power3 } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);
import { breakpoint } from '../utils/dom';

require('waypoints/lib/noframework.waypoints.min');

const tocRemovedEvent = "toc-removed";

/*
 * Add the following to the html body to overide the default toc items.
 *
 *   <div id="toc" selector=".tocEntry">
 *   </div>
 */

class Toc extends View {
  selector = 'h2, h3, .tocEntry';
  firstLevelSelector;
  secondLevelSelector;
  thirdLevelSelector;
  fourthLevelSelector;
  fifthLevelSelector;

  constructor(node) {
    super(node);

    this.chapterMenu = this.node.querySelector('.chapter-menu');
    this.chapterMenuList = this.chapterMenu.querySelector('ul');
    this.article = document.querySelector('.article-output');
    this.tocContainer = this.node.querySelector('ul.plain');
    this.mobileTocHeader = this.node.querySelector('.chapter-menu-title.mobile');
    this.waypoints = new Map();

    if (window.location.hash) {
      setTimeout(() => {
        this.scrollToItem(window.location.hash.substring(1), null, 0.1);
      }, 200);
    }

    setTimeout(() => {
      this.firstHighlight = document.querySelector('span.highlight');
      if (this.firstHighlight) {
        this.scrollToItem(null, null);
      }
    }, 200);

    events.on('add-same-page-anchor-listener', el => {
      el.addEventListener('click', e => {
        if (e.target.pathname === window.location.pathname) {
          e.preventDefault();
          this.scrollToItem(e.target.hash.substring(1), null, 0.1);
        }
      });
    });

    this.mobileTocHeader.addEventListener('click', e => {
      if (e.target.classList.contains('active')) {
        this.closeTocList();
      } else {
        this.openTocList();
      }
    });

    this.tocContainer.addEventListener('click', e => {
      if (e.target.tagName === 'A') {
        this.scrollToItem(null, e.target.dataset.target, 0.8);

        if (breakpoint('large-down')) {
          this.closeTocList();
        }
      }
    });
    this.getTocItems();
    this.defaultActive();

    window.addEventListener(tocRemovedEvent, e => {
      this.removeTocItem(e.target);
    });
  }

  /**
   * Get the TOC items (h2 - h6 and .tocEntry)
   */
  getTocItems = async () => {
    let toc = this.article.querySelector('#toc');
    if (toc !== null) {
      if (typeof toc.attributes['selector'] !== 'undefined') {
        this.selector = toc.attributes['selector'].value;
      } else {
        this.selector = '';
      }
    }
    let tocItems = [];
    if (this.selector !== 'undefined' && this.selector !== '') {
      tocItems = this.article.querySelectorAll(this.selector);
    }

    // Only display the TOC if there are items
    if (tocItems.length !== 0) {
      this.chapterMenu.classList.remove('hidden');
      this.node.classList.remove('hidden');

      for (let i = 0, len = tocItems.length; i < len; i++) {
        let item = this.getTocItemDetails(tocItems[i]);
        if (item.title.length > 0) {
          let itemDataToc = 'toc-' + i;
          tocItems[i].dataset.toc = itemDataToc;
          this.tocContainer.innerHTML +=
            '<li class="' +
            item.level +
            '"><a class="chapter-link" data-target="' +
            itemDataToc +
            '">' +
            item.title +
            '</a></li>';

          // set waypoint (this detects if the element is in the current viewport)
          this.setWaypoint(itemDataToc);
        }
      }
    }
  };

  /**
   * Get TOC item details (level, title and id)
   * @param tocItem
   * @returns {{}}
   */
  getTocItemDetails = tocItem => {
    let details = {};
    let levelNumber = 0;

    if (tocItem.localName.length === 2) {
      levelNumber = this.getLevelNumberForHeader(tocItem);
    } else {
      levelNumber = this.getLevelNumberForClass(tocItem);
    }
    details.level = 'level-' + levelNumber;
    details.title = tocItem.dataset.tocheadingtext
      ? tocItem.dataset.tocheadingtext
      : tocItem.textContent.replace(/^\s+|\s+$/g, '').match(/(.*)/)[0];

    return details;
  };

  /**
   * Get the level number, keeping in mind that even h4 can be the first level :-(
   * @param tocItem
   * @returns {number}
   */
  getLevelNumberForHeader = tocItem => {
    let level = 5; // if no level determinable, place on level 5

    if (
      typeof this.firstLevelSelector === 'undefined' ||
      tocItem.localName === this.firstLevelSelector
    ) {
      level = 1;
      this.firstLevelSelector = tocItem.localName;
    } else if (
      typeof this.secondLevelSelector === 'undefined' ||
      tocItem.localName === this.secondLevelSelector
    ) {
      level = 2;
      this.secondLevelSelector = tocItem.localName;
    } else if (
      typeof this.thirdLevelSelector === 'undefined' ||
      tocItem.localName === this.thirdLevelSelector
    ) {
      level = 3;
      this.thirdLevelSelector = tocItem.localName;
    } else if (
      typeof this.fourthLevelSelector === 'undefined' ||
      tocItem.localName === this.fourthLevelSelector
    ) {
      level = 4;
      this.fourthLevelSelector = tocItem.localName;
    } else if (
      typeof this.fifthLevelSelector === 'undefined' ||
      tocItem.localName === this.fifthLevelSelector
    ) {
      level = 5;
      this.fifthLevelSelector = tocItem.localName;
    }

    return level;
  };

  /**
   * Get the level number (indentation level) based on the toclevel data attribute
   * @param tocItem
   * @returns {number}
   */
  getLevelNumberForClass = tocItem => {
    let level = 1;
    if (typeof tocItem.dataset.toclevel !== 'undefined') {
      level = tocItem.dataset.toclevel;
    }
    return level;
  };

  /**
   * Use the waypoint library to determine when an element is in view and touches the top
   * @param datasetToc
   */
  setWaypoint = datasetToc => {
    let that = this;
    let element = document.querySelector('[data-toc=' + datasetToc + ']');

    // one for scrolling up
    const waypointUp = new Waypoint({
      element: element,
      handler: function(direction) {
        if (direction === 'up') {
          let tocItems = that.tocContainer.querySelectorAll('li a');
          for (let i = 0; i < tocItems.length; i++) {
            tocItems[i].parentNode.classList.remove('active');
          }
          const target = that.tocContainer.querySelector('li a[data-target="' + datasetToc + '"]');
          target.parentNode.classList.add('active');
          const targetTop = target.parentNode.offsetTop;

          //Whenever we hit this waypoint, we want to make sure the table of contents scroll (so if we scroll in the text, the TOC also scrolls).
          that.chapterMenu.scrollTo(0, targetTop - 15);
        }
      },
      offset: 70, // react when element is 70 px from top of page
    });

    // one for scrolling down
    const waypointDown = new Waypoint({
      element: element,
      handler: function(direction) {
        if (direction === 'down') {
          let tocItems = that.tocContainer.querySelectorAll('li a');
          for (let i = 0; i < tocItems.length; i++) {
            tocItems[i].parentNode.classList.remove('active');
          }
          const target = that.tocContainer.querySelector('li a[data-target="' + datasetToc + '"]');
          target.parentNode.classList.add('active');
          const targetTop = target.parentNode.offsetTop;

          //Whenever we hit this waypoint, we want to make sure the table of contents scroll (so if we scroll in the text, the TOC also scrolls).
          that.chapterMenu.scrollTo(0, targetTop - 15);
        }
      },
      offset: 125, // react when element is 125 px from top of page
    });

    this.waypoints.set(element, {up: waypointUp, down: waypointDown});
  };

  /**
   * Scroll to an element by using GSAP.
   * @param id
   * @param dataAttribute
   * @param duration
   */
  scrollToItem = (id, dataAttribute, duration) => {
    let positions;
    if (id !== null) {
      positions = document.getElementById(id).getBoundingClientRect();
    } else if (dataAttribute !== null) {
      positions = document
        .querySelector('[data-toc=' + dataAttribute + ']')
        .getBoundingClientRect();
    } else {
      positions = this.firstHighlight.getBoundingClientRect();
    }
    let scrollTop =
      window.scrollY ||
      window.pageYOffset ||
      document.body.scrollTop +
        ((document.documentElement && document.documentElement.scrollTop) || 0);
    const offset = window.innerWidth < 850 ? 60 : window.innerWidth < 1100 ? 150 : 120;
    let top = scrollTop + (positions.top - offset);

    let e = { y: scrollTop };
    gsap.to(e, { duration, y: top, onUpdate: () => window.scrollTo(0, e.y) });
  };

  /**
   * on small screens toggle the toc list after click heading h3
   */

  openTocList = () => {
    this.mobileTocHeader.classList.toggle('active');

    const tl = gsap.timeline();
    tl.fromTo(this.tocContainer,
      {
        height: 0,
        paddingTop: 0,
        paddingBottom: 0,
        visibility: 'visible',
      },
      {
        duration: 0.5,
        height: 'auto',
        paddingTop: 30,
        paddingBottom: 20,
        ease: Power3.easeInOut,
      });
  };

  /**
   * on small screens toggle the toc list after click heading h3
   */

  closeTocList = () => {
    this.mobileTocHeader.classList.toggle('active');

    const tl = gsap.timeline();
    tl.to(this.tocContainer,
      {
        duration: 0.5,
        height: 0,
        paddingTop: 0,
        paddingBottom: 0,
        ease: Power3.easeInOut,
      })
      .set(this.tocContainer, {
        visibility: 'hidden',
      });
  };

  defaultActive = () => {
    const noActiveItems = this.tocContainer.querySelector('.active') === null;
    const firstTocItem = this.tocContainer.querySelector('li');
    if (noActiveItems && firstTocItem) {
      this.tocContainer.querySelector('li').classList.add('active');
    }
  };

  /* Removes an item from the table of contents by using its data string */
  removeTocItem = (originalElement) => {
    const tocItemData = originalElement.dataset.toc;
    const element = this.tocContainer.querySelector(`[data-target="${tocItemData}"]`);
    element.remove();

    if (this.waypoints.has(originalElement)) {
      const waypoints = this.waypoints.get(originalElement);
      waypoints.up.destroy();
      waypoints.down.destroy();

      this.waypoints.delete(originalElement);
    }
  };
}

export default Toc;
export { tocRemovedEvent };