import View from '../../View';

class SearchAutoComplete extends View {
  activeItem = null;
  currentSearch = '';

  // This autocomplete is basically only for int he extensive filters. The difference between this autocomplete and the autocomplete-direct is that the autocomplete-direct redirects the user when they click or enter
  // On a result, whereas this autocomplete will add it to the respective filter.

  constructor(node) {
    super(node);

    this.autocompleteUl = this.node.parentNode.querySelector('ul.autocomplete');
    this.homeFooter = document.querySelector('.sources-overview');
    this.homeSearch = this.node.closest('.home-search');
    this.searchForm = this.node.closest('.search-form');
    this.filterContent = this.node.closest('.search-filter-content');
    this.expandableContent = this.node.closest('.expandable-content');
    this.node.addEventListener('keyup', this.keyUpHandler);
    this.node.addEventListener('keydown', this.keyDownHandler);

    this.isExtensiveSearch = this.node.closest('.extensive-search-form') !== null;
    this.isSearchFilter = this.filterContent !== null;

    this.searchType =
      !this.isExtensiveSearch ||
      (this.isExtensiveSearch &&
        this.node.closest('.extensive-search-form').classList.contains('kennisbank'))
        ? 'kennisbank'
        : 'IM';

    // If a LI is clicked, set it'sdata attribute value in the search field and submit the field.
    if (this.autocompleteUl !== null) {
      this.autocompleteUl.addEventListener('click', this.handleAutocompleteResultClick);
    }

    // if there is clicked somewhere outside of the autocomplete AND the filter, empty and hide
    window.addEventListener('click', this.handleOutsideClick);

    // Sometimes we emit events from different places that make us want to remove all auto suggestions. I.E: adding an included searchterm via extensive search with the 'Voeg toe' button.
    events.on('clearAutoSuggest', this.clearResults);
  }

  /**
   * Dispose of event listeners for the current instance of the js component
   */
  dispose() {
    this.node.removeEventListener('keyup', this.keyUpHandler);
    this.autocompleteUl.removeEventListener('click', this.handleAutocompleteResultClick);
    window.removeEventListener('click', this.handleOutsideClick);
  }

  /**
   * Handle keyUp events
   * @param e
   */
  keyUpHandler = e => {
    e.preventDefault();

    let q = this.node.value;

    // start searching at 3 characters length
    if (q.length < 3) {
      this.clearResults();
      return;
    }

    switch (e.which) {
      case 13: // enter
        this.clearResults();
        break;
      case 27: // esc
        this.clearResults();
        break;
      case 40: // keyDown
        this.navigateDown();
        break;
      case 38: // keyUp
        this.navigateUp();
        break;
      case 37: // keyLeft
      case 39: // keyRight
        // do nothing
        break;
      default:
        this.autoComplete(q);
        break;
    }
  };

  /**
   * KeyDown handler to prevent enter on autocomplete to submit form on extensive search forms
   * @param e
   */
  keyDownHandler = e => {
    if (e.which === 13) {
      if (this.isExtensiveSearch) {
        e.preventDefault();
        return false;
      }
    }
  };

  /**
   * Get the autocomplete results
   * @param q
   */
  autoComplete = q => {
    let url = `/api/autocomplete?q=${q}`;
    if (typeof this.node.dataset.source !== 'undefined') {
      url = `/api/autocomplete?q=${q}&source=${this.node.dataset.source}`;
    }

    // get the response from the api
    this.fetchAsync(url, { method: 'GET', credentials: 'same-origin' }).then(data => {
      this.currentSearch = this.node.value;
      this.clearResults();
      let results = data.data;
      Object.keys(results).forEach(key => {
        this.autocompleteUl.innerHTML +=
          '<li data-link="' +
          results[key].autocomplete +
          '" class="title">' +
          results[key].autocomplete +
          '</li>';
      });
      if (results.length > 0) {
        this.autocompleteUl.classList.add('active');
        if (this.homeFooter) this.homeFooter.classList.add('autocomplete');
        if (this.homeSearch) this.homeSearch.style.overflow = 'visible';
        if (this.isExtensiveSearch) this.searchForm.style.overflow = 'visible';
        if (this.isSearchFilter) {
          this.expandableContent.style.overflow = 'visible';
          this.filterContent.style.overflow = 'visible';
        }
      } else {
        this.autocompleteUl.classList.remove('active');
        if (this.homeFooter) this.homeFooter.classList.remove('autocomplete');
        if (this.isExtensiveSearch) this.searchForm.style.overflow = 'hidden';
        if (this.isSearchFilter) {
          this.expandableContent.style.overflow = 'hidden';
          this.filterContent.style.overflow = 'hidden';
        }
      }
    });
  };

  /**
   * Handle the click on an autocomplete result
   * @param e
   */
  handleAutocompleteResultClick = e => {
    if (
      e.target.tagName === 'LI' &&
      e.target.parentNode !== null &&
      e.target.parentNode.classList.contains('autocomplete') &&
      e.target.dataset.link
    ) {
      if (this.node.name === 'includedWords') {
        events.emit('addIncludedWordFromAutoSuggest', { event: e, searchType: this.searchType });
        this.clearResults();
      } else if (this.node.name === 'excludedWords') {
        events.emit('addExcludedWordFromAutoSuggest', { event: e, searchType: this.searchType });
        this.clearResults();
      } else {
        //quick search, we want to redirect to search page.
        if (this.searchForm) {
          this.node.value = e.target.dataset.link;
          this.searchForm.submit();
        }
      }
    }
  };

  /**
   * Handle a click outside of the autocomplete area
   * @param e
   */
  handleOutsideClick = e => {
    if (e.target.closest('.autocomplete-bounds') === null) {
      this.clearResults();
    }
  };

  /**
   * Do an asynchronous call to the api
   * @param url
   * @param request
   * @param opts
   * @returns {Promise.<{data: *}>}
   */
  fetchAsync = async (url, request, opts = {}) => {
    // await response of fetch call
    let response = await fetch(url, request);
    // only proceed once promise is resolved
    let data = await response.json();
    // only proceed once second promise is resolved
    return { data, ...opts };
  };

  /**
   * Handle the navigation down through the autocomplete results
   */
  navigateDown = () => {
    if (this.activeItem) {
      this.activeItem.classList.remove('active');
      if (this.activeItem.nextSibling) {
        this.activeItem = this.activeItem.nextSibling;
        this.node.value = this.activeItem.dataset.link;
        this.activeItem.classList.add('active');
      } else {
        this.activeItem = null;
        this.node.value = this.currentSearch;
      }
    } else {
      // set the first autocomplete item as active item
      this.activeItem = this.autocompleteUl.firstChild;
      this.node.value = this.activeItem.dataset.link;
      this.activeItem.classList.add('active');
    }
  };

  /**
   * Handle the navigation up through the autocomplete results
   */
  navigateUp = () => {
    if (this.activeItem) {
      this.activeItem.classList.remove('active');
      if (this.activeItem.previousSibling) {
        this.activeItem = this.activeItem.previousSibling;
        this.node.value = this.activeItem.dataset.link;
        this.activeItem.classList.add('active');
      } else {
        this.activeItem = null;
        this.node.value = this.currentSearch;
      }
    } else {
      // set the first autocomplete item as active item
      this.activeItem = this.autocompleteUl.lastChild;
      this.node.value = this.activeItem.dataset.link;
      this.activeItem.classList.add('active');
    }
  };

  /**
   * Clear the autocomplete results
   */
  clearResults = () => {
    this.node.parentNode.querySelector('ul.autocomplete').innerHTML = '';
    this.autocompleteUl.classList.remove('active');
    this.node.parentNode.classList.remove('autocomplete-open');
    if (this.homeFooter) this.homeFooter.classList.remove('autocomplete');
    if (this.isExtensiveSearch) this.searchForm.style.overflow = 'hidden';
    this.activeItem = null;
  };

  /**
   * Submit the form
   */
  submitForm = () => {
    this.searchForm.querySelector('form').submit();
  };
}

export default SearchAutoComplete;
