/**
 * todo Yeah, make this work..
 * idea was to save and load it al from one place. and not the you have these function in every file you need this functionality (like it is now)
 *
 *
 * Saves and loads localstorage
 * @type {LocalStorage}
 */
const LocalStorage = new (class LocalStorage {
  constructor() {}

  /**
   * Saves data to the local storage
   * @param name
   * @param data
   */
  save = (name, data) => {
    localStorage[name] = JSON.stringify(data);
  };

  /**
   * Loads data from the local storage
   * @param name
   * @returns Object
   */
  load = name => {
    try {
      return JSON.parse(localStorage[name]);
    } catch (e) {
      return {};
    }
  };
})();
