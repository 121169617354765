import View from '../View';
import { breakpoint } from '../utils/dom';

class PageOptions extends View {
  constructor(node) {
    super(node);

    this.dropdown = this.node;
    this.wrapperElement = this.node.parentNode;
    this.node.addEventListener('click', this.openDropdown);
  }

  openDropdown = e => {
    this.wrapperElement.classList.toggle('open');
  };
}

export default PageOptions;
