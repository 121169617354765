import View from '../View';

class Session extends View {
  constructor(node) {
    super(node);
    setInterval(this.updateLoginSession, 180000);
  }

  updateLoginSession = () => {
    let url = '/api/updateSession';
    let headers = new Headers();
    fetch(url, { headers, method: 'GET', credentials: 'same-origin' });
  };
}

export default Session;
