import View from '../View';
import { gsap, Power3 } from 'gsap';

class BackToTop extends View {
  tween = { y: 0 };
  scrollY = 0;

  constructor(node) {
    super(node);
    this.backToTopButton = this.node;
    this.footer = document.getElementById('footer');

    events.on('stickyHeaderToggle', this.toggleVisibility);

    document.addEventListener('scroll', e => {
      this.onScroll();
    });
    this.backToTopButton.addEventListener('click', e => {
      this.scrollToOffset(0);
    });

    this.makeBackToTopSticky();
  }

  toggleVisibility = () => {
    this.backToTopButton.classList.toggle('hidden');
  };

  scrollToOffset = offset => {
    if (!this.tween) return;
    this.tween.y = this.scrollY;
    gsap.to(this.tween, {
      duration: 1,
      y: offset - this.node.clientHeight + 1, // TOC element is visually 1px shorter
      ease: Power3.easeInOut,
      onUpdate: () => {
        window.scrollTo(0, this.tween.y);
      },
    });
  };

  makeBackToTopSticky = () => {
    let that = this;
    new Waypoint({
      element: that.footer,
      handler: function() {
        that.backToTopButton.classList.toggle('fixed');
      },
      offset: '100%', // react when element is 30 px from top of page
    });
  };

  onScroll = () => {
    this.scrollY =
      (window.pageYOffset || document.documentElement.scrollTop) -
      (document.documentElement.clientTop || 0);
  };
}

export default BackToTop;
