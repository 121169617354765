import View from '../../View';

class SearchAutoCompleteDirect extends View {
  activeItem = null;
  currentSearch = '';

  constructor(node) {
    super(node);

    this.autocompleteUl = this.node.parentNode.querySelector('ul.autocomplete');
    this.homeSearch = this.node.closest('.home-search');
    this.searchForm = this.node.closest('.search-form');
    this.node.addEventListener('keyup', this.keyUpHandler);
    this.node.addEventListener('keydown', this.keyDownHandler);

    if (this.autocompleteUl !== null) {
      // If a LI is clicked, set it'sdata attribute value in the search field and submit the field.
      this.autocompleteUl.addEventListener('click', this.handleAutocompleteResultClick);
    }

    // if there is clicked somewhere outside of the autocomplete AND the filter, empty and hide
    window.addEventListener('click', this.handleOutsideClick);
  }

  /**
   * Dispose of event listeners for the current instance of the js component
   */
  dispose() {
    this.node.removeEventListener('keyup', this.keyUpHandler);
    this.autocompleteUl.removeEventListener('click', this.handleAutocompleteResultClick);
    window.removeEventListener('click', this.handleOutsideClick);
  }

  /**
   * Handle keyUp events
   * @param e
   */
  keyUpHandler = e => {
    e.preventDefault();

    let q = this.node.value;

    // start searching at 3 characters length
    if (q.length < 3) {
      this.clearResults();
      return;
    }

    switch (e.which) {
      case 13: // enter
        let activeLI = this.autocompleteUl.querySelector('li.active');

        if (activeLI !== null) {
          if (window.location.pathname === '/') {
            localStorage['sidebar'] = '';
          }
          window.location.href =
            window.location.protocol + '//' + window.location.host + '/' + activeLI.dataset.link;
        } else {
          this.submitForm();
        }
        this.clearResults();
        break;
      case 27: // esc
        this.clearResults();
        break;
      case 40: // keyDown
        this.navigateDown();
        break;
      case 38: // keyUp
        this.navigateUp();
        break;
      case 37: // keyLeft
      case 39: // keyRight
        // do nothing
        break;
      default:
        this.autoComplete(q);
        break;
    }
  };

  /**
   * KeyDown handler to prevent enter on autocomplete to submit form on extensive search forms
   * @param e
   */
  keyDownHandler = e => {
    if (e.which === 13) {
      if (this.searchForm.classList.contains('extensive-search-form')) {
        e.preventDefault();
        return false;
      }
    }
  };

  /**
   * Get the autocomplete results
   * @param q
   */
  autoComplete = q => {
    let url = `/api/autocomplete-direct?q=${q}`;
    if (typeof this.node.dataset.source !== 'undefined') {
      url = `/api/autocomplete-direct?q=${q}&source=${this.node.dataset.source}`;
    }

    // get the response from the api
    this.fetchAsync(url, { method: 'GET', credentials: 'same-origin' }).then(data => {
      this.currentSearch = this.node.value;
      this.clearResults();
      let results = data.data.articles || [];

      let maxResults = results.length < 10 ? results.length : 10;
      for (let i = 0; i < maxResults; i++) {
        let li =
          '<li data-link="' +
          results[i].url +
          '" data-title="' +
          results[i].title +
          '">' +
          '<span class="title">' +
          results[i].title +
          '</span>' +
          '</li>';
        this.autocompleteUl.insertAdjacentHTML('beforeEnd', li);
      }
      if (results.length > 0) {
        this.autocompleteUl.classList.add('active');
        this.node.parentNode.classList.add('autocomplete-open');
        if (this.homeSearch) this.homeSearch.style.overflow = 'visible';
        this.searchForm.style.overflow = 'visible';
      } else {
        this.autocompleteUl.classList.remove('active');
        this.node.parentNode.classList.remove('autocomplete-open');
      }
    });
  };

  /**
   * Handle the click on an autocomplete result
   * @param e
   */
  handleAutocompleteResultClick = e => {
    if (
      e.target.parentNode.tagName === 'LI' &&
      e.target.parentNode !== null &&
      e.target.parentNode.parentNode.classList.contains('autocomplete')
    ) {
      if (window.location.pathname === '/') {
        localStorage['sidebar'] = '';
      }
      window.location.href =
        window.location.protocol +
        '//' +
        window.location.host +
        '/' +
        e.target.parentNode.dataset.link;
      this.clearResults();
    }
  };

  /**
   * Handle a click outside of the autocomplete area
   * @param e
   */
  handleOutsideClick = e => {
    if (e.target.closest('.search-form') === null) {
      this.clearResults();
    }
  };

  /**
   * Do an asynchronous call to the api
   * @param url
   * @param request
   * @param opts
   * @returns {Promise.<{data: *}>}
   */
  fetchAsync = async (url, request, opts = {}) => {
    // await response of fetch call
    let response = await fetch(url, request);
    // only proceed once promise is resolved
    let data = await response.json();
    // only proceed once second promise is resolved
    return { data, ...opts };
  };

  /**
   * Handle the navigation down through the autocomplete results
   */
  navigateDown = () => {
    if (this.activeItem) {
      this.activeItem.classList.remove('active');
      if (this.activeItem.nextSibling) {
        this.activeItem = this.activeItem.nextSibling;
        this.node.value = this.activeItem.dataset.title;
        this.activeItem.classList.add('active');
      } else {
        this.activeItem = null;
        this.node.value = this.currentSearch;
      }
    } else {
      // set the first autocomplete item as active item
      this.activeItem = this.autocompleteUl.getElementsByTagName('li')[0];
      this.node.value = this.activeItem.dataset.title;
      this.activeItem.classList.add('active');
    }
  };

  /**
   * Handle the navigation up through the autocomplete results
   */
  navigateUp = () => {
    if (this.activeItem) {
      this.activeItem.classList.remove('active');
      if (this.activeItem.previousSibling) {
        this.activeItem = this.activeItem.previousSibling;
        this.node.value = this.activeItem.dataset.title;
        this.activeItem.classList.add('active');
      } else {
        this.activeItem = null;
        this.node.value = this.currentSearch;
      }
    } else {
      // set the first autocomplete item as active item
      this.activeItem = this.autocompleteUl.lastChild;
      this.node.value = this.activeItem.dataset.title;
      this.activeItem.classList.add('active');
    }
  };

  /**
   * Clear the autocomplete results
   */
  clearResults = () => {
    let results = this.autocompleteUl.querySelectorAll('li');
    for (let i = 0; i < results.length; i++) {
      results[i].parentElement.removeChild(results[i]);
    }
    this.autocompleteUl.classList.remove('active');
    this.node.parentNode.classList.remove('autocomplete-open');
    this.activeItem = null;
  };

  /**
   * Submit the form
   */
  submitForm = () => {
    this.searchForm.querySelector('form').submit();
  };
}

export default SearchAutoCompleteDirect;
