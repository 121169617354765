import View from '../../View';
import { saveSearch, getSearch } from '../../utils/search';

class SearchResults extends View {
  searchData = {};

  constructor(node) {
    super(node);

    if (window.history.replaceState) {
      window.history.replaceState(null, null, window.location.href);
    }

    this.searchUrl = '/api/searchResults';

    // clear the sidebar so it won't show on the next detail page
    localStorage['sidebar'] = '';

    this.view = this.node;

    this.sortingDropdown = this.node.querySelector('.dropdown-sorting');
    this.searchInTitle = document.querySelector('.search-in-title');
    this.searchTerm = this.node.querySelector('.search-term');
    this.resultList = this.node.querySelector('.result-list');
    this.resultsCounter = this.node.querySelector('.results-counter');

    this.searchData = this.getDefaultSearchData();
    saveSearch(this.searchData, true);

    this.sortingDropdown.addEventListener('change', this.updateSearch);
    this.searchInTitle.addEventListener('change', this.updateSearch);

    //Listen to custom events triggert by filter.js from all the filter checkboxes.
    events.on('filterUpdate', this.updateSearch);
    //Listen to custom events triggert by filter.js from the quickSearchDropdown
    events.on('filterUpdate2', e => {
      this.updateSearch(e);
    });
    //Listen to custom events triggert by filter.js from the quickSearchDropdown
    events.on('filterUpdate3', sources => {
      this.updateSearchFromCheckboxes(sources);
    });
    //Listen to custom events triggert when a check needs to be added to the searchData and be selected
    events.on('addDefaultCheck', this.addCheck);

    //Pagination
    this.resultList.addEventListener('click', e => {
      if (
        (e.target.nodeName === 'BUTTON' && e.target.classList.contains('pager-item')) ||
        e.target.closest('.pager-arrow')
      ) {
        this.handlePagerClick(e);
      }
    });
  }

  /**
   * Updates the result view
   * @param e
   */
  updateSearch = e => {
    if (typeof e !== 'undefined') {
      if (e.target) {
        e.preventDefault();
        //Adding a filter
        if (!e.target.classList.contains('select-filter-button')) {
          this.addCheck(e.target);
        }
      } else {
        this.addCheck(e);
      }
    }

    this.searchData = getSearch();
    const data = new FormData();
    data.append('json', JSON.stringify(this.searchData));

    //fetch and update the html
    let headers = new Headers();

    this.fetchAsync(this.searchUrl, {
      headers,
      method: 'POST',
      body: data,
      credentials: 'same-origin',
    }).then(data => {
      const receivedData = data.data;

      this.resultsCounter.innerHTML = receivedData.count;
      this.resultList.innerHTML = receivedData.articles;
      saveSearch(this.searchData, true);
    });
  };

  /**
   * Updates the result view
   * @param e
   */
  updateSearchFromCheckboxes = sources => {
    this.searchData = getSearch();

    Object.keys(sources).forEach(key => {
      if (this.searchData.hasOwnProperty(sources[key].id)) {
        if (sources[key].status === 'close') {
          delete this.searchData[sources[key].id];
        }
      } else {
        if (sources[key].status === 'open') {
          this.searchData[sources[key].id] = sources[key].value;
        }
      }
    });

    const data = new FormData();
    data.append('json', JSON.stringify(this.searchData));

    //fetch and update the html
    let headers = new Headers();

    this.fetchAsync(this.searchUrl, {
      headers,
      method: 'POST',
      body: data,
      credentials: 'same-origin',
    }).then(data => {
      const receivedData = data.data;

      this.resultsCounter.innerHTML = receivedData.count;
      this.resultList.innerHTML = receivedData.articles;
      saveSearch(this.searchData, true);
    });
  };

  /**
   * Returns the default search query and loads the searchData from the default searchData is there is any
   * @returns Object
   */
  getDefaultSearchData = () => {
    //Sets the form data of all the filters needed for the update
    let tempSearchData = {};
    tempSearchData.sort = this.sortingDropdown.value;
    tempSearchData.searchInTitle = this.searchInTitle.checked;
    tempSearchData.searchTerm = this.searchTerm.value;
    // tempSearchData.showDescription = this.showDescriptionToggle.checked;
    return tempSearchData;
  };

  /**
   * Do an asynchronous call to the api
   * @param url
   * @param request
   * @param opts
   * @returns {Promise.<{data: *}>}
   */
  fetchAsync = async (url, request, opts = {}) => {
    // await response of fetch call
    let response = await fetch(url, request);
    // only proceed once promise is resolved
    let data = await response.json();
    // only proceed once second promise is resolved
    return { data, ...opts };
  };

  /**
   * Handles the pagination clicker
   * @param e
   */
  handlePagerClick = e => {
    //If a click is done on one of the arrows it might be on the svg which doens't have the target page so we look for the button element
    let targetPage = e.target.dataset.target || e.target.closest('.pager-arrow').dataset.target;

    let searchData = getSearch();
    searchData.start = (parseInt(targetPage) - 1) * 100;

    const data = new FormData();
    data.append('json', JSON.stringify(searchData));

    //fetch and update the html
    this.fetchAsync(this.searchUrl, { method: 'POST', body: data }).then(data => {
      const receivedData = data.data;
      this.resultList.innerHTML = receivedData.articles;
    });

    // scroll to top
    this.scrollToOffset(0);
  };

  /**
   * Handles scroll
   * @param offset
   */
  scrollToOffset = offset => {
    window.scrollTo({
      top: offset,
      behavior: "smooth",
    });
  };

  /**
   * Adds a filter to the search query
   * 1. Adds filter to the searchData
   * 2. Checks a filter
   * 3. Adds/removes a filter from the included filters.
   * @param element
   */
  addCheck = element => {
    if (element.classList.contains('filter')) {
      if (element.checked) {
        events.emit('addSelectedFilter', element);
        if (element.classList.contains('search-on-filter')) {
          this.searchData[element.value] = true;
          const elementToCheck = element.parentNode.parentNode.parentNode.parentNode.parentNode
            .querySelector('.expandable-header')
            .querySelector('input');
          if (element.value.indexOf('filter') !== -1 && elementToCheck.checked === false) {
            elementToCheck.click();
          }
        } else {
          const expandableHeader = element.closest('.expandable-header');
          expandableHeader.classList.add('active');
          if (element.classList.contains('search-on-source')) {
            const mainLevel = element.closest('.mainlevel');
            mainLevel.querySelector('.expandable-header').classList.add('active');
            mainLevel.querySelector('input').checked = true;
          }
          this.searchData[element.value] = element.dataset.selectId;
        }
      } else {
        events.emit('removeSelectedFilterPill', element);
        delete this.searchData[element.value];
      }
    } else if (element.classList.contains('search-in-title')) {
      if (element.checked) {
        element.parentNode.querySelector('.checkbox-text').classList.add('active');
        events.emit('addSelectedFilter', element);
        this.searchData['searchInTitle'] = true;
      } else {
        element.parentNode.querySelector('.checkbox-text').classList.remove('active');
        events.emit('removeSelectedFilterPill', element);
        delete this.searchData[element.name];
      }
    } else if (element.classList.contains('sorting')) {
      this.searchData['sort'] = element.value;
    }
    //Save the searchData after every search update
    saveSearch(this.searchData, true);
  };
}

export default SearchResults;
