import View from '../../View';

class SearchAutoCompleteDynamic extends View {
  activeItem = null;
  currentSearch = '';

  //This autocomplete is only used in the header, where we want to switch between the autocomplete direct and 'autosuggest'
  //There are some differences with the 2 seperate file, mainly dealing with the fact that the markup for both autosuggests are slightly different
  //And ofcourse checking whether or not we need to use the autocomplete or direct search function.

  constructor(node) {
    super(node);

    this.autocompleteUl = this.node.parentNode.querySelector('ul.autocomplete');
    this.searchForm = this.node.closest('.search-form');
    this.node.addEventListener('keyup', this.keyUpHandler);
    this.node.addEventListener('keydown', this.keyDownHandler);

    // Create and start mutation observer for data source
    this.nodeDataSrouceObserver = new MutationObserver(this.dataSourceObserverHandler)
    this.nodeDataSrouceObserver.observe(this.node, {
      attributes: true,
      attributeFilter: ["data-source"]
    });

    // If a LI is clicked, set its data attribute value in the search field and submit the field.
    if (this.autocompleteUl !== null) {
      this.autocompleteUl.addEventListener('click', this.handleAutocompleteResultClick);
    }

    // if there is clicked somewhere outside of the autocomplete AND the filter, empty and hide
    window.addEventListener('click', this.handleOutsideClick);

    // Sometimes we emit events from different places that make us want to remove all auto suggestions. I.E: adding an included searchterm via extensive search with the 'Voeg toe' button.
    events.on('clearAutoSuggest', this.clearResults);
  }

  /**
   * Dispose of event listeners for the current instance of the js component
   */
  dispose() {
    this.node.removeEventListener('keyup', this.keyUpHandler);
    this.autocompleteUl.removeEventListener('click', this.handleAutocompleteResultClick);
    window.removeEventListener('click', this.handleOutsideClick);
    this.nodeDataSrouceObserver.disconnect();

  }

  /**
   * Handle keyUp events
   * @param e
   */
  keyUpHandler = e => {
    e.preventDefault();

    let q = this.node.value;

    // start searching at 3 characters length
    if (q.length < 3) {
      this.clearResults();
      return;
    }

    switch (e.which) {
      case 13: // enter
        if (this.node.dataset.source) {
          let activeLI = this.autocompleteUl.querySelector('li.active');
          if (activeLI !== null) {
            if (window.location.pathname === '/') {
              localStorage['sidebar'] = '';
            }
            window.location.href =
              window.location.protocol + '//' + window.location.host + '/' + activeLI.dataset.link;
          } else {
            this.submitForm();
          }
        }
        this.clearResults();
        break;
      case 27: // esc
        this.clearResults();
        break;
      case 40: // keyDown
        this.navigateDown();
        break;
      case 38: // keyUp
        this.navigateUp();
        break;
      case 37: // keyLeft
      case 39: // keyRight
        // do nothing
        break;
      default:
        if (this.node.dataset.source) {
          this.autoCompleteDirect(q);
        } else {
          this.autoCompleteSearch(q);
        }
        break;
    }
  };

  /**
   * KeyDown handler to prevent enter on autocomplete to submit form on extensive search forms
   * @param e
   */
  keyDownHandler = e => {
    if (e.which === 13) {
      if (this.isExtensiveSearch) {
        e.preventDefault();
        return false;
      }
    }
  };

  /**
   * Handle data source change, call autocomplete again
   */
  dataSourceObserverHandler = () => {
    let q = this.node.value;

    // start searching at 3 characters length
    if (q.length < 3) {
      this.clearResults();
      return;
    }

    if (this.node.dataset.source) {
      this.autoCompleteDirect(q);
    } else {
      this.autoCompleteSearch(q);
    }
  }

  /**
   * Get the autocomplete results
   * @param q
   */
  autoCompleteSearch = q => {
    let url = `/api/autocomplete?q=${q}`;
    if (typeof this.node.dataset.source !== 'undefined') {
      url = `/api/autocomplete?q=${q}&source=${this.node.dataset.source}`;
    }

    // get the response from the api
    this.fetchAsync(url, { method: 'GET', credentials: 'same-origin' }).then(data => {
      this.currentSearch = this.node.value;
      this.clearResults();
      let results = data.data;
      Object.keys(results).forEach(key => {
        this.autocompleteUl.innerHTML +=
          '<li data-link="' +
          results[key].autocomplete +
          '" class="title">' +
          results[key].autocomplete +
          '</li>';
      });
      if (results.length > 0) {
        this.autocompleteUl.classList.add('active');
        if (this.isSearchFilter) {
          this.expandableContent.style.overflow = 'visible';
          this.filterContent.style.overflow = 'visible';
        }
      } else {
        this.autocompleteUl.classList.remove('active');
        if (this.isSearchFilter) {
          this.expandableContent.style.overflow = 'hidden';
          this.filterContent.style.overflow = 'hidden';
        }
      }
    });
  };

  /**
   * Get the autocomplete results
   * @param q
   */
  autoCompleteDirect = q => {
    let url = `/api/autocomplete-direct?q=${q}`;
    if (typeof this.node.dataset.source !== 'undefined') {
      url = `/api/autocomplete-direct?q=${q}&source=${this.node.dataset.source}`;
    }

    // get the response from the api
    this.fetchAsync(url, { method: 'GET', credentials: 'same-origin' }).then(data => {
      this.currentSearch = this.node.value;
      this.clearResults();
      let results = data.data.articles || [];
      let maxResults = results.length < 10 ? results.length : 10;
      for (let i = 0; i < maxResults; i++) {
        let li =
          '<li data-link="' +
          results[i].url +
          '" data-title="' +
          results[i].title +
          '">' +
          '<span class="title">' +
          results[i].title +
          '</span>' +
          '</li>';
        this.autocompleteUl.insertAdjacentHTML('beforeEnd', li);
      }
      if (results.length > 0) {
        this.autocompleteUl.classList.add('active');
        this.node.parentNode.classList.add('autocomplete-open');
        if (this.homeSearch) this.homeSearch.style.overflow = 'visible';
        this.searchForm.style.overflow = 'visible';
      } else {
        this.autocompleteUl.classList.remove('active');
        this.node.parentNode.classList.remove('autocomplete-open');
      }
    });
  };

  /**
   * Handle the click on an autocomplete result
   * @param e
   */
  handleAutocompleteResultClick = e => {
    if (this.node.dataset.source) {
      if (
        e.target.parentNode.tagName === 'LI' &&
        e.target.parentNode !== null &&
        e.target.parentNode.parentNode.classList.contains('autocomplete')
      ) {
        window.location.href =
          window.location.protocol +
          '//' +
          window.location.host +
          '/' +
          e.target.parentNode.dataset.link;
        this.clearResults();
      }
    } else {
      if (
        e.target.tagName === 'LI' &&
        e.target.parentNode !== null &&
        e.target.parentNode.classList.contains('autocomplete') &&
        e.target.dataset.link &&
        this.searchForm
      ) {
        this.node.value = e.target.dataset.link;
        this.searchForm.submit();
      }
    }
  };

  /**
   * Handle a click outside of the autocomplete area
   * @param e
   */
  handleOutsideClick = e => {
    if (e.target.closest('.autocomplete-bounds') === null) {
      this.clearResults();
    }
  };

  /**
   * Do an asynchronous call to the api
   * @param url
   * @param request
   * @param opts
   * @returns {Promise.<{data: *}>}
   */
  fetchAsync = async (url, request, opts = {}) => {
    // await response of fetch call
    let response = await fetch(url, request);
    // only proceed once promise is resolved
    let data = await response.json();
    // only proceed once second promise is resolved
    return { data, ...opts };
  };

  /**
   * Handle the navigation down through the autocomplete results
   */
  navigateDown = () => {
    const isDirect = this.node.dataset.source ? true : false;
    if (this.activeItem) {
      this.activeItem.classList.remove('active');
      if (this.activeItem.nextSibling) {
        this.activeItem = this.activeItem.nextSibling;
        const activeItemValue = isDirect
          ? this.activeItem.dataset.title
          : this.activeItem.dataset.link;
        this.node.value = activeItemValue;
        this.activeItem.classList.add('active');
      } else {
        this.activeItem = null;
        this.node.value = this.currentSearch;
      }
    } else {
      // set the first autocomplete item as active item
      this.activeItem = this.autocompleteUl.firstChild;
      const activeItemValue = isDirect
        ? this.activeItem.dataset.title
        : this.activeItem.dataset.link;

      this.node.value = activeItemValue;
      this.activeItem.classList.add('active');
    }
  };

  /**
   * Handle the navigation up through the autocomplete results
   */
  navigateUp = () => {
    if (this.activeItem) {
      this.activeItem.classList.remove('active');
      if (this.activeItem.previousSibling) {
        this.activeItem = this.activeItem.previousSibling;
        const activeItemValue = this.node.dataset.source
          ? this.activeItem.dataset.title
          : this.activeItem.dataset.link;
        this.node.value = activeItemValue;
        this.activeItem.classList.add('active');
      } else {
        this.activeItem = null;
        this.node.value = this.currentSearch;
      }
    } else {
      // set the first autocomplete item as active item
      this.activeItem = this.autocompleteUl.lastChild;
      const activeItemValue = this.node.dataset.source
        ? this.activeItem.dataset.title
        : this.activeItem.dataset.link;
      this.node.value = activeItemValue;
      this.activeItem.classList.add('active');
    }
  };

  /**
   * Clear the autocomplete results
   */
  clearResults = () => {
    this.node.parentNode.querySelector('ul.autocomplete').innerHTML = '';
    this.autocompleteUl.classList.remove('active');
    this.node.parentNode.classList.remove('autocomplete-open');
    if (this.homeFooter) this.homeFooter.classList.remove('autocomplete');
    if (this.isExtensiveSearch) this.searchForm.style.overflow = 'hidden';
    this.activeItem = null;
  };

  /**
   * Submit the form
   */
  submitForm = () => {
    this.searchForm.querySelector('form').submit();
  };
}

export default SearchAutoCompleteDynamic;
