import View from '../../View';

class SearchTabs extends View {
  constructor(node) {
    super(node);

    this.kennisbankSearch = document.querySelector('#search-kennisbank');
    this.IMSearch = document.querySelector('#search-im');
    this.showKennisbankSearchButton = this.node.querySelector('.kennisbank-search-button');
    this.showIMSearchButton = this.node.querySelector('.im-search-button');
    this.showIMExtensiveSearchButton = this.node.querySelector('.im-extensive-search-button');
    this.showKennisbankExtensiveSearchButton = this.node.querySelector(
      '.kennisbank-extensive-search-button'
    );

    this.showIMFiltersButton = this.IMSearch.querySelector('.open-im-search-filters');
    this.IMFiltersOverlay = this.IMSearch.querySelector('.extensive-search-form');
    this.closeIMFilterOverlayButton = this.IMSearch.querySelectorAll(
      '.close-extensive-search-form'
    );

    this.showKennisbankFiltersButton = this.kennisbankSearch.querySelector(
      '.open-kennisbank-search-filters'
    );
    this.kennisBankFiltersOverlay = this.kennisbankSearch.querySelector(' .extensive-search-form');
    this.closeKennisbankOverlayButton = this.kennisbankSearch.querySelectorAll(
      '.close-extensive-search-form'
    );

    this.showKennisbankSearchButton.addEventListener('click', this.showKennisbankSearch);
    this.showIMSearchButton.addEventListener('click', this.showIMSearch);
    this.showKennisbankFiltersButton.addEventListener('click', this.toggleKennisbankFilters);
    this.showIMFiltersButton.addEventListener('click', this.toggleIMFilters);
    this.showIMExtensiveSearchButton.addEventListener('click', e =>
      this.switchFilterView(e, 'kennisbank')
    );
    this.showKennisbankExtensiveSearchButton.addEventListener('click', e =>
      this.switchFilterView(e, 'IM')
    );

    this.closeIMFilterOverlayButton.forEach(item => {
      item.addEventListener('click', this.toggleIMFilters);
    });

    this.closeKennisbankOverlayButton.forEach(item => {
      item.addEventListener('click', this.toggleKennisbankFilters);
    });

    const homepageSearchTabPreference = localStorage.getItem('homepageSearchTab');
    if (homepageSearchTabPreference === null || homepageSearchTabPreference === 'kennisbank') {
      this.initialOpening('kb');
    } else {
      this.initialOpening('im');
    }
  }

  initialOpening = preference => {
    if (preference === 'im') {
      //classes are fine as they are.
      localStorage.setItem('homepageSearchTab', 'im');
    } else {
      this.IMSearch.classList.add('closed');
      this.kennisbankSearch.classList.add('open');
      localStorage.setItem('homepageSearchTab', 'kennisbank');
    }
  };

  showKennisbankSearch = e => {
    if (e) {
      e.preventDefault();
    }
    this.IMSearch.classList.toggle('closed');
    this.kennisbankSearch.classList.toggle('open');

    localStorage.setItem('homepageSearchTab', 'kennisbank');

    const imInput = this.IMSearch.querySelector('.search-im-quick');
    const kennisbankInput = this.kennisbankSearch.querySelector('.search-kennisbank-quick');
    if (kennisbankInput && imInput) {
      const currentQuickTerm = imInput.value;
      kennisbankInput.value = currentQuickTerm;
    }
  };

  showIMSearch = e => {
    if (e) {
      e.preventDefault();
    }
    // events.emit('IMActivate');
    this.IMSearch.classList.toggle('closed');
    this.kennisbankSearch.classList.toggle('open');

    localStorage.setItem('homepageSearchTab', 'im');

    const imInput = this.IMSearch.querySelector('.search-im-quick');
    const kennisbankInput = this.kennisbankSearch.querySelector('.search-kennisbank-quick');
    if (kennisbankInput && imInput) {
      const currentQuickTerm = kennisbankInput.value;
      imInput.value = currentQuickTerm;
    }
  };

  toggleKennisbankFilters = e => {
    if (e) {
      e.preventDefault();
    }

    this.kennisBankFiltersOverlay.classList.toggle('hide');
  };

  toggleIMFilters = e => {
    if (e) {
      e.preventDefault();
    }

    this.IMFiltersOverlay.classList.toggle('hide');
  };

  switchFilterView = (e, currentView) => {
    if (e) {
      e.preventDefault();
    }

    this.IMFiltersOverlay.classList.toggle('hide');
    this.kennisbankSearch.classList.toggle('open');

    this.IMSearch.classList.toggle('closed');
    this.kennisBankFiltersOverlay.classList.toggle('hide');

    let currentForm = null;

    if (currentView == 'kennisbank') {
      currentForm = document.querySelector('.extensive-search-form.kennisbank');
    } else {
      currentForm = document.querySelector('.extensive-search-form.im');
    }

    if (!currentForm) return;

    const currentIncludedInput = currentForm.querySelector('input[name=includedWords]');
    const currentExcludedInput = currentForm.querySelector('input[name=excludedWords]');
    if (!currentIncludedInput || !currentExcludedInput) return;
    const currentIncludedInputTerm = currentIncludedInput.value;
    const currentExcludedInputTerm = currentExcludedInput.value;

    //This event is handled in extensive.js and matches the current value of the included word input
    events.emit('matchIncludedWordInput', {
      searchType: currentView,
      inputValue: currentIncludedInputTerm,
    });

    events.emit('matchExcludedWordInput', {
      searchType: currentView,
      inputValue: currentExcludedInputTerm,
    });
  };
}

export default SearchTabs;
