import View from '../View';
import { gsap, Power3 } from 'gsap';

class ArticleTools extends View {
  constructor(node) {
    super(node);

    this.tools = this.node;
    this.printButton = this.tools.querySelector('.print');
    this.expandableToggleButton = this.node.querySelector('.toggle');
    this.expandableToggleButtonClosed = true;

    this.printButton.addEventListener('click', e => {
      this.printArticle();
    });

    window.addEventListener('keydown', e => {
      if (e.ctrlKey && (e.key == 'p' || e.charCode == 16 || e.charCode == 112 || e.keyCode == 80)) {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.cancelBubble = true;
        this.printArticle();
      }
    });

    //Make the expandable toggle button work
    if (!this.makeToggleButtonWork()) {
      this.expandableToggleButton.remove();
    }
  }

  printArticle = () => {
    window.print();
  };

  makeToggleButtonWork = () => {
    //Get all expandables
    const article = document.querySelector('article');
    const elements = article.querySelectorAll('.expandable');

    //If there are more expandables then unhide the expandableToggleButton in the toolbar and set the listeners
    let expandableContent;
    if (elements.length > 0) {
      this.expandableToggleButton.classList.remove('hidden');
      this.expandableToggleButton.addEventListener('click', e => {
        //Loop through all the expandable elements
        for (let i = 0; i < elements.length; i++) {
          //Get the particular element and toggle it
          expandableContent = elements[i].querySelectorAll('.expandable-content')[0];
          if (this.expandableToggleButtonClosed) {
            this.openExpandable(elements[i], expandableContent);
          } else {
            this.closeExpandable(elements[i], expandableContent);
          }
        }

        // Toggle the toggleButton to 'open all' or 'close all'
        this.expandableToggleButtonClosed = !this.expandableToggleButtonClosed;
        if (this.expandableToggleButtonClosed) {
          this.expandableToggleButton.classList.add('closed');
          this.expandableToggleButton.classList.remove('opened');
        } else {
          this.expandableToggleButton.classList.remove('closed');
          this.expandableToggleButton.classList.add('opened');
        }
      });
    } else {
      return false;
    }
    return true;
  };

  //Open expandable
  openExpandable = (element, content) => {
    if (!element.classList.contains('open')) {
      gsap.fromTo(content, 
        { height: 0 },
        { 
          duration: 0.7,
          height: "auto",
          ease: Power3.easeInOut
      });
    }

    element.classList.add('open');
  };

  //Close expandable
  closeExpandable = (element, content) => {
    if (element.classList.contains('open')) {
      gsap.to(content, {
        duration: 0.7,
        height: 0,
        ease: Power3.easeInOut,
      });
    }

    element.classList.remove('open');
  };
}

export default ArticleTools;
