import View from '../../View';
import { breakpoint } from '../../utils/dom';
import { gsap, Power3 } from 'gsap';
import { saveSearch, getSearch } from '../../utils/search';

class SearchResultFilters extends View {
  constructor(node) {
    super(node);
    this.openFiltersButton = this.node.querySelector('.search-filter-header--mobile');
    this.filterContent = this.node.querySelector('.search-filter-content');
    this.searchResults = document.querySelector('.search-results');
    this.clearFilterBtn = document.querySelector('.clear-filters-button');
    this.searchHeaderKeywordsWrapper = document.querySelector('.search-keywords');
    this.applyFiltersBtn = this.node.querySelector('.apply-filters-button');
    this.selectedFiltersContainer = this.node.querySelector('.chosen-filters-container');
    this.searchFilterContent = this.node.querySelector('.search-filter-content');
    this.searchInTitleCheckbox = this.node.querySelector('.search-in-title');

    this.excludedWordInput = this.node.querySelector('.withoutWordsInput');
    this.addExcludedWordButton = this.node.querySelector('.button-add-excluded-word');
    this.excludedWordsContainer = this.node.querySelector('.excluded-words-container');

    this.searchOnDomains = this.node.querySelectorAll('.search-on-domain');
    this.searchOnSource = this.node.querySelectorAll('.search-on-source');
    this.searchOnFilter = this.node.querySelectorAll('.search-on-filter');

    this.hasNoFiltersText = true;

    this.openFiltersButton.addEventListener('click', this.toggleFilters);
    if (breakpoint('large-down')) {
      this.applyFiltersBtn.addEventListener('click', this.toggleFilters);
    }

    this.clearFilterBtn.addEventListener('click', this.clearFilters);

    this.excludedWordInput.addEventListener('keydown', e => this.handleExcludedWordInput(e));
    this.addExcludedWordButton.addEventListener('click', e => this.addExcludedWordClick(e, true));

    //Add event listeners on al the filter checkboxes.
    this.searchOnDomains.addEventListeners('change', e => {
      const expandHeader = e.target.closest('.expandable-header');
      if (e.target.checked) {
        this.toggleChildren(e, 'open');
        expandHeader.classList.add('active');
      } else {
        this.toggleChildren(e, 'close');
        expandHeader.classList.remove('active');
      }
    });
    this.searchOnSource.addEventListeners('change', e => {
      this.sourceClickHandler(e);
      events.emit('filterUpdate', e);
    });
    this.searchOnFilter.addEventListeners('change', function(e) {
      events.emit('filterUpdate', e);
    });

    //Checks al the checks that come from the web/searchController.php when the page first loads.
    this.checkForDefaultChecks(this.searchOnDomains);
    this.checkForDefaultChecks(this.searchOnSource);
    this.checkForDefaultChecks(this.searchOnFilter);
    this.checkForDefaultChecks([this.searchInTitleCheckbox]);
    this.checkForDefaultExcludedWords();

    events.on('addSelectedFilter', element => this.addSelectedFilter(element));
    events.on('removeSelectedFilterPill', element => this.removeSelectedFilterPill(element));
    events.on('addExcludedWordFromAutoSuggest', ({ event }) =>
      this.addExcludedWordClick(event, false)
    );
  }

  handleExcludedWordInput = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      events.emit('clearAutoSuggest');
      this.addExcludedWordClick(e, true);
    }
  };

  removeKeyword(e, element = null) {
    if (e) {
      e.preventDefault();
      e.target.parentNode.remove();
    } else {
      element.remove();
    }
  }

  toggleFilters = e => {
    e.preventDefault();

    if (this.openFiltersButton.classList.contains('active')) {
      this.hideFilters();
    } else {
      this.showFilters();
    }
  };

  hideFilters = () => {
    this.openFiltersButton.classList.remove('active');

    const tl = gsap.timeline();

    tl.to(this.filterContent,
      {
        duration: 0.5,
        height: 0,
        paddingTop: 0,
        paddingBottom: 0,
        ease: Power3.easeInOut,
      })
      .set(this.filterContent, {
        visibility: 'hidden',
      });
  };

  showFilters = () => {
    this.openFiltersButton.classList.add('active');

    const tl = gsap.timeline();
    tl.fromTo(this.filterContent,
      {
        height: 0,
        paddingTop: 0,
        paddingBottom: 0,
        visibility: 'visible',
      }, {
        duration: 0.5,
        height: 'auto',
        paddingTop: 30,
        paddingBottom: 20,
        ease: Power3.easeInOut,
      });
  };

  /**
   * Checks if there ar checks within the array and then add it to the searchData in results.js :: addCheck()
   * @param elementArray
   */
  checkForDefaultChecks = elementArray => {
    for (let key in elementArray) {
      if (elementArray[key].checked) {
        this.addSelectedFilter(elementArray[key]);
        events.emit('addDefaultCheck', elementArray[key]);
      }
    }
  };

  checkForDefaultExcludedWords() {
    const searchData = getSearch();
    const searchTerms = searchData.searchTerm;
    //Because of the way we send the excluded words in the new search, we are guaranteed that the excluded words are one after another.
    //Splitting the string on +- will grant us an array [all included words, excluded 1, excluded 2] etc. (also works if there are no included words)
    const splitTerms = searchTerms.split('+-');
    splitTerms.shift();

    if (splitTerms.length > 0) {
      const expandHeader = this.node.querySelector('.withoutWords-expandable-element');

      expandHeader.classList.add('active');
      expandHeader.parentNode.classList.add('open');
    }

    for (let i = 0; i < splitTerms.length; i++) {
      const initialTerm = splitTerms[i];
      //This does the same as PHP's decode.
      const term = decodeURIComponent(initialTerm.replace(/\+/g, ' '));

      const withoutKeyword = document.createElement('span');
      withoutKeyword.className = 'filter-word-instance excluded-word-instance';
      withoutKeyword.dataset.term = term;
      withoutKeyword.dataset.selectId = `without-${term}`;
      withoutKeyword.innerHTML = `<span>${term}</span>
      <button class="delete-excluded-word-button x-button x-button--blue"></button>`;
      this.addSelectedFilter(withoutKeyword, true);

      withoutKeyword
        .querySelector('.delete-excluded-word-button')
        .addEventListener('click', () => this.removeExcludedWord(withoutKeyword.dataset.selectId));

      //Add above input field.
      this.excludedWordsContainer.appendChild(withoutKeyword);
    }
  }

  /**
   * Uncheck al the pre selected filters.
   */
  uncheckAllFiltersAndClose = () => {
    const filters = [this.searchOnDomains, this.searchOnSource, this.searchOnFilter];

    let searchInTitleElement = this.node.querySelector('.search-in-title');
    searchInTitleElement.checked = false;

    //Loop through all the filters to uncheck them (resource heavy)
    for (let key in filters) {
      for (let keyB in filters[key]) {
        if (
          typeof keyB !== 'number' &&
          typeof filters[key][keyB] !== 'number' &&
          typeof filters[key][keyB] !== 'function'
        ) {
          filters[key][keyB].checked = false;

          let parent = filters[key][keyB].closest('.expandable');
          const header = parent.querySelector('.expandable-header');
          if (parent.classList.contains('open')) {
            this.close(parent);
          }
          header.classList.remove('active');
        }
      }
    }

    //Clean last search..
    let search = getSearch();
    for (let key in search) {
      if (key.indexOf('domains') >= 0 || key.indexOf('source') >= 0 || key.indexOf('filter') >= 0) {
        delete search[key];
      }
    }
    saveSearch(search);
  };

  //Clicking on the top most filter option (Medicatiebewaking for example.)
  toggleChildren = (e, status) => {
    let mainlevel = e.target.closest('.expandable');
    if (status === 'open') {
      if (!mainlevel.classList.contains('open')) {
        this.open(mainlevel);
      }
    } else {
      this.close(mainlevel);
    }
    let content = mainlevel.querySelector('.expandable-content');
    let expandable = content.querySelectorAll('.expandable');
    let checkboxes = content.querySelectorAll(
      'input[type="checkbox"]:not(.options):not(.disabled)'
    );

    const expandableArray = Array.from(expandable);
    //If the clicked element only has 1 expandable child (notably the upper IM filter), we also open the child.
    if (expandableArray.length === 1 && status === 'open') {
      this.open(expandableArray[0]);
    }

    Object.keys(expandable).forEach(expandableKey => {
      const expandableItem = expandable[expandableKey];
      const expandableHeader = expandableItem.querySelector('.expandable-header');
      if (status !== 'open') {
        if (!expandableItem.classList.contains('empty')) {
          this.close(expandableItem);
        }
        if (expandableHeader.classList.contains('active')) {
          expandableHeader.classList.remove('active');
        }
      } else {
        expandableHeader.classList.add('active');
      }
    });
    let checkedboxes = [];
    Object.keys(checkboxes).forEach(checkboxKey => {
      const checkboxElement = checkboxes[checkboxKey];
      if (status === 'open') {
        if (!checkboxElement.checked) {
          this.addSelectedFilter(checkboxElement);
        }
        checkboxElement.checked = true;
      } else {
        if (checkboxElement.checked) {
          this.removeSelectedFilter(null, checkboxElement);
        }
      }
      checkedboxes.push({
        id: checkboxes[checkboxKey].id,
        value: checkboxes[checkboxKey].id.replace(/(^.*\[|\].*$)/g, ''),
        status: status,
      });
    });
    events.emit('filterUpdate3', checkedboxes);
  };

  sourceClickHandler = e => {
    let parentTheme = e.target
      .closest('.mainlevel')
      .querySelector('.expandable-header')
      .querySelector('input');
    const sourceHeader = e.target.closest('.expandable-header');

    if (!e.target.checked) {
      parentTheme.checked = false;
      let filters = e.target
        .closest('.expandable')
        .querySelector('.expandable-content')
        .querySelectorAll('input');
      Object.keys(filters).forEach(key => {
        if (filters[key].checked) {
          filters[key].click();
        }
      });
      const mainLevelHeader = e.target.closest('.mainlevel').querySelector('.expandable-header');
      if (mainLevelHeader.classList.contains('active')) {
        mainLevelHeader.classList.remove('active');
      }
      sourceHeader.classList.remove('active');
    } else {
      let sources = e.target
        .closest('.mainlevel')
        .querySelectorAll('.search-on-source:not(.disabled)');
      let allSources = sources.length;
      let activeSources = 0;

      Object.keys(sources).forEach(key => {
        if (sources[key].checked) {
          activeSources++;
        }
      });
      sourceHeader.classList.add('active');
      if (allSources === activeSources) {
        parentTheme.checked = true;
        e.target
          .closest('.mainlevel')
          .querySelector('.expandable-header')
          .classList.add('active');
      }
    }
  };

  getSelectedFilterInfo = element => {
    //We want to add this to the selected filters section.
    const filterDataID = element.dataset.selectId;
    let filterName = '';
    if (element.classList.contains('search-on-filter')) {
      filterName = element.parentNode.querySelector('.title').innerHTML;
    } else if (element.classList.contains('search-on-source')) {
      const completeInnerHtml = element.parentNode.parentNode.querySelector('.item').innerHTML;
      const splitString = completeInnerHtml.split('<span>');
      filterName = splitString[0].trim();
    } else if (element.classList.contains('search-in-title')) {
      filterName = 'Alleen in titel';
    } else if (element.classList.contains('excluded-word-instance')) {
      const excludedTerm = element.dataset.term;
      filterName = `Zonder: ${excludedTerm}`;
    }

    return {
      dataId: filterDataID,
      name: filterName,
    };
  };

  addSelectedFilter = (element, isExcludedWord = false) => {
    this.removeNoFiltersText();
    const { dataId, name } = this.getSelectedFilterInfo(element);
    const selectedFilterMarkup = document.createElement('span');
    selectedFilterMarkup.className = 'filter-word-instance';
    selectedFilterMarkup.dataset.selectId = dataId;
    selectedFilterMarkup.innerHTML = `
      <span>${name}</span>
      <button class="delete-included-filter-button x-button x-button--blue"></button>
    `;

    if (isExcludedWord) selectedFilterMarkup.dataset.type = 'excluded-word';

    selectedFilterMarkup
      .querySelector('.delete-included-filter-button')
      .addEventListener('click', e => this.removeSelectedFilter(e));

    this.selectedFiltersContainer.appendChild(selectedFilterMarkup);
  };

  addExcludedWordClick(e, fromInput) {
    if (e) {
      e.preventDefault();
    }
    const term = fromInput ? this.excludedWordInput.value : e.target.dataset.link;
    if (!term) return;

    const withoutKeyword = document.createElement('span');
    withoutKeyword.className = 'filter-word-instance excluded-word-instance';
    withoutKeyword.dataset.term = term;
    withoutKeyword.dataset.selectId = `without-${term}`;

    // When adding an excluded word, we need to do a couple of things:
    // 1: Add an included word-pill above the excluded word input
    // 2: Add an included word-pill to the selected filters (if checked)
    // 3: Update the searchTerm in the localstorage and execute a new search (if checked)
    // 4: Update the search results header to show the newly added term. (if checked)
    withoutKeyword.innerHTML = `<span>${term}</span>
    <button class="delete-excluded-word-button x-button x-button--blue"></button>`;

    this.addSelectedFilter(withoutKeyword, true);
    this.addExcludedWordToSearchheader(term);

    const currentSearchData = getSearch();
    const newSearchTerm = `${currentSearchData.searchTerm}+-${term}`;
    currentSearchData.searchTerm = newSearchTerm;
    saveSearch(currentSearchData);
    events.emit('filterUpdate');

    this.excludedWordsContainer.appendChild(withoutKeyword);

    withoutKeyword
      .querySelector('.delete-excluded-word-button')
      .addEventListener('click', () => this.removeExcludedWord(withoutKeyword.dataset.selectId));

    this.excludedWordInput.value = '';
    events.emit('clearAutoSuggest');
  }

  addExcludedWordToSearchheader(term) {
    const excludedKeyword = document.createElement('span');
    excludedKeyword.className = 'keyword';
    excludedKeyword.innerText = term;
    excludedKeyword.dataset.term = term;

    //Check if there are already excluded keywords first
    let excludedKeyWordWrapper = Array.from(
      this.searchHeaderKeywordsWrapper.querySelectorAll('.keyword-type-wrapper')
    )[1];

    if (!excludedKeyWordWrapper) {
      //If there is no excluded keywords wrapper to start with, we want to create it and the label, so we can add the excluded keyword to it later.
      const keyWordTypeWrapper = document.createElement('span');
      keyWordTypeWrapper.className = 'keyword-type-wrapper';

      const keyWordLabelElement = document.createElement('span');
      keyWordLabelElement.className = 'keywordTypeLabel';
      keyWordLabelElement.innerText = 'zonder ';

      keyWordTypeWrapper.appendChild(keyWordLabelElement);
      this.searchHeaderKeywordsWrapper.appendChild(keyWordTypeWrapper);
      excludedKeyWordWrapper = keyWordTypeWrapper;
    }

    excludedKeyWordWrapper.appendChild(excludedKeyword);
  }

  removeSelectedFilter = (event, element = null, fullyDeleteExcludedWord = true) => {
    const target = event ? event.target.parentNode : element;
    const dataTerm = target.dataset.selectId;

    if (target.dataset.type === 'excluded-word' && fullyDeleteExcludedWord) {
      //This function is also executed when clicking the x button of the pill above the input.
      this.removeExcludedWord(dataTerm);
    } else {
      //We need to uncheck the box before we delete the markup item.
      const elementsArray = Array.from(
        this.searchFilterContent.querySelectorAll(`input[data-select-id="${dataTerm}"]`)
      );

      // Sometimes a 2nd level item has the same name as the first level item (IM for example).
      const elementToUncheck = elementsArray[elementsArray.length - 1];
      elementToUncheck.click();
    }

    if (this.selectedFiltersContainer && !this.selectedFiltersContainer.hasChildNodes()) {
      this.showNoFiltersText();
    }
  };

  removeExcludedWord = dataTerm => {
    //Remove searchterm and search again.
    const excludeTerm = dataTerm.substring(8);

    //Remove pill above input
    const inputPill = this.excludedWordsContainer.querySelector(`[data-select-id="${dataTerm}"]`);
    this.removeKeyword(null, inputPill);

    //If this checkbox is checked, there is some extra markup we will need to remove (included search filters + search results header.)
    //If it's checked that also means we use these words in the search so we must update the search results.
    //Remove term from search results header.
    this.removeExcludedWordFromHeader(excludeTerm);

    //Remove pill in chosen filters section.
    const selectedFiltersPill = this.selectedFiltersContainer.querySelector(
      `[data-select-id="${dataTerm}"]`
    );
    this.removeKeyword(null, selectedFiltersPill);
    const currentSearchData = getSearch();
    const newSearchTerm = currentSearchData.searchTerm.replace(`+-${excludeTerm}`, '');
    currentSearchData.searchTerm = newSearchTerm;
    saveSearch(currentSearchData);
    events.emit('filterUpdate');
    if (this.selectedFiltersContainer && !this.selectedFiltersContainer.hasChildNodes()) {
      this.showNoFiltersText();
    }
  };

  //This is meant to remove the pill in the selected filters section, the 'normal' removeSelectedFilter is to uncheck the box and remove from the search.
  removeSelectedFilterPill = element => {
    const { dataId } = this.getSelectedFilterInfo(element);
    const includedFilterPill = this.selectedFiltersContainer.querySelector(
      `[data-select-id="${dataId}"]`
    );
    if (includedFilterPill !== null) {
      includedFilterPill.remove();
    }
  };

  removeExcludedWordFromHeader(term) {
    //Get the excluded words wrapper.
    let excludedKeyWordWrapper = Array.from(
      this.searchHeaderKeywordsWrapper.querySelectorAll('.keyword-type-wrapper')
    )[1];

    if (!excludedKeyWordWrapper) return; //This shouldn't happen.
    const allTerms = Array.from(excludedKeyWordWrapper.querySelectorAll('.keyword'));
    if (allTerms.length <= 1) {
      //If this is the only excluded keyword we can just remove the whole wrapper.
      excludedKeyWordWrapper.remove();
    } else {
      const termElement = excludedKeyWordWrapper.querySelector(`[data-term="${term}"]`);
      termElement.remove();
    }
  }

  /**
   * Open an expandable
   * @param expandableElement
   */
  open = expandableElement => {
    let content = expandableElement.children[1];
    let tl = gsap.timeline({ onComplete: this.refreshWaypoints });
    tl.fromTo(content,
      {
        height: 0
      },
      {
        duration: 0.7,
        height: 'auto',
        ease: Power3.easeInOut,
    });
    expandableElement.classList.add('open');
  };

  /**
   * Close an expandable
   * @param expandableElement
   */
  close = expandableElement => {
    let content = expandableElement.children[1];
    let tl = gsap.timeline({ onComplete: this.refreshWaypoints });
    tl.to(content, {
      duration: 0.7,
      height: 0,
      ease: Power3.easeInOut,
    });

    expandableElement.classList.remove('open');
  };

  refreshWaypoints = () => {
    Waypoint.refreshAll();
  };

  /**
   * Clear all filters and reset search
   * @param e
   */
  clearFilters = e => {
    this.uncheckAllFiltersAndClose();
    this.clearSearch();
    this.showNoFiltersText();
    this.selectedFiltersContainer.innerHTML = '';
    this.excludedWordsContainer.innerHTML = '';

    const excludedInSearchheader = Array.from(
      this.searchHeaderKeywordsWrapper.querySelectorAll('.keyword-type-wrapper')
    )[1];
    if (excludedInSearchheader) excludedInSearchheader.remove();

    events.emit('filterUpdate');
  };

  clearSearch = () => {
    let searchDataOld = getSearch();
    let searchData = {};
    const newSearchTerm = searchDataOld.searchTerm.split('+-')[0];
    searchData.sort = searchDataOld.sort;
    searchData.searchTerm = newSearchTerm;
    searchData.searchInTitle = false;
    searchData.showDescription = false;

    saveSearch(searchData, true);
  };

  removeNoFiltersText = () => {
    if (!this.hasNoFiltersText) return;
    const noFiltersText = this.node.querySelector('.no-chosen-filters-text');
    if (!noFiltersText) return;
    this.hasNoFiltersText = false;
    noFiltersText.classList.add('hidden');
  };

  showNoFiltersText = () => {
    if (this.hasNoFiltersText) return;
    const noFiltersText = this.node.querySelector('.no-chosen-filters-text');
    if (!noFiltersText) return;
    this.hasNoFiltersText = true;
    noFiltersText.classList.remove('hidden');
  };
}

export default SearchResultFilters;
