import View from '../View';

import { gsap, Power3 } from 'gsap';

class Accordion extends View {
  constructor(node) {
    super(node);

    this.accordionElement = this.node;
    this.accordionTitle = this.node.querySelector('.accordionTitle');
    this.accordionBody = this.node.querySelector('.accordionBody');

    this.accordionTitle.addEventListener('click', this.handleClick);
  }
  handleClick = e => {
    e.preventDefault();
    if (this.accordionElement.classList.contains('open')) {
      this.close();
    } else {
      this.open();
    }
  };

  open = () => {
    let tl = gsap.timeline({ onComplete: this.refreshWaypoints });
    tl.fromTo(this.accordionBody,
      {
        height: 0,
      },
      {
        duration: 0.7,
        height: 'auto',
        ease: Power3.easeInOut,
    });
    this.accordionElement.classList.add('open');
  };

  close = () => {
    let tl = gsap.timeline({ onComplete: this.refreshWaypoints });
    tl.to(this.accordionBody, {
      duration: 0.7,
      height: 0,
      ease: Power3.easeInOut,
    });

    this.accordionElement.classList.remove('open');
  };
  refreshWaypoints = () => {
    Waypoint.refreshAll();
  };
}

export default Accordion;
