/**
 * @browser Gecko
 * @param {array} types
 * @param {Function} listener
 * @param {boolean} [useCapture]
 */
HTMLCollection.prototype.addEventListeners = NodeList.prototype.addEventListeners = function(
  types,
  listener,
  useCapture = false
) {
  for (let i = 0; i < this.length; i++) {
    this[i].addEventListener(types, listener, useCapture);
  }
};

/**
 * Converts HTMLCollection object to an array
 * @returns {Array}
 */
HTMLCollection.prototype.toArray = NodeList.prototype.toArray = function() {
  let array = [];
  for (let key in this) {
    array[key] = this[key];
  }
  return array;
};

/**
 * Removes multiple elements.
 * @type {NodeList.remove}
 */
HTMLCollection.prototype.remove = NodeList.prototype.remove = function() {
  for (let i = this.length - 1; i >= 0; i--) {
    if (this[i] && this[i].parentElement) {
      this[i].parentElement.removeChild(this[i]);
    }
  }
};
