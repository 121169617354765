import View from '../View';

class Cookiebar extends View {
  constructor(node) {
    super(node);

    this.acceptButton = this.node.querySelector('.cookiebar-acceptButton');
    this.acceptButton.addEventListener('click', this.closeCookieBar);
  }

  closeCookieBar = () => {
    document.cookie = 'cookiebarClosed=true;expires=Wed, 01 Jan 2108 12:00:00 UTC; path=/; SameSite=Lax';
    this.node.classList.add('hidden');
  };
}

export default Cookiebar;
