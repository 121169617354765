import config from '../config';
/**
 * Checks the current page width against a media range.
 * String values are defined in the breakpoints config.
 *
 * @param {string|Object} range - A key used in the media range config or a custom range object
 * @returns {bool} Whether the page width sits inside the range
 *
 * @example
 * breakpoint('large-up')
 * breakpoint({ min: 900 })
 * breakpoint({ min: 300, max: 900 })
 */
function breakpoint(range) {
  const width = window.innerWidth;
  const { mediaRanges, breakpoints } = config;

  let min, max;

  if (typeof range === 'object') {
    min = range.min || 0;
    max = range.max || Infinity;
  } else {
    const minKey = mediaRanges[range].min;
    const maxKey = mediaRanges[range].max;

    min = breakpoints[minKey] || 0;
    max = breakpoints[maxKey] || Infinity;
  }

  return width > min && width < max;
}

function isOrContainsNode(parent, child) {
  return parent === child || containsNode(parent, child);
}

function containsNode(parent, child) {
  return parent.contains(child);
}

function isTargetWithin(eventTarget, parent, includeParent = false, checkActiveElement = true) {
  const checkFunc = includeParent ? isOrContainsNode : containsNode;
  return (
    checkFunc(parent, eventTarget) ||
    (checkActiveElement && checkFunc(parent, document.activeElement))
  );
}

export { breakpoint, isTargetWithin };
