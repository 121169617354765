import Layout from './components/layout';
import Session from './components/session';
import Header from './components/header';
import Tree from './components/tree';
import Toc from './components/toc';
import BackToTop from './components/back-to-top';
import RecentMessage from './components/recent-message';
import Expandable from './components/expandable';
import ContentFromSource from './components/content-from-source';
import Tables from './components/tables';
import TableSort from './components/table-sort';
import ArticleHeaderImage from './components/header-image';
import ArticleTools from './components/article-tools';
import SearchAutoComplete from './components/search/autocomplete';
import SearchAutoCompleteDirect from './components/search/autocomplete-direct';
import SearchAutoCompleteDynamic from './components/search/autocomplete-dynamic';
import SearchResults from './components/search/results';
import SearchTabs from './components/search/tabs';
import SearchResultFilters from './components/search/filters';
import SearchResultTools from './components/search/search-result-tools';
import ExtensiveSearchForm from './components/search/extensive';
import Accordion from './components/accordion';
import PageOptions from './components/pageOptions';
import { default as RelatedDocuments, relatedDocumentsSelector } from './components/related-documents';
import BackButton from './components/back-button';
import TreeToggle from './components/treeToggle';
import DisabledTreeToggle from './components/disabledTreeToggle';
import AttachedSearchFilters from './components/attachedSearchFilters';
import Cookiebar from './components/cookiebar';

export default [
  {
    selector: 'body',
    component: Layout,
  },
  {
    selector: '.js-session',
    component: Session,
  },
  {
    selector: 'header',
    component: Header,
  },
  {
    selector: '.js-tree',
    component: Tree,
  },
  {
    selector: '.js-tree-button',
    component: TreeToggle,
  },
  {
    selector: '.js-disabled-tree-button',
    component: DisabledTreeToggle,
  },
  {
    selector: '.js-toc',
    component: Toc,
  },
  {
    selector: '.js-back-to-top',
    component: BackToTop,
  },
  {
    selector: '.js-recent-message',
    component: RecentMessage,
  },
  {
    selector: '.expandable',
    component: Expandable,
  },
  {
    selector: '.js-content-from-source',
    component: ContentFromSource,
  },
  {
    selector: '.header-image',
    component: ArticleHeaderImage,
  },
  {
    selector: 'table',
    component: Tables,
  },
  {
    selector: '.table',
    component: TableSort,
  },
  {
    selector: '.js-article-tools',
    component: ArticleTools,
  },
  {
    selector: '.js-search-autocomplete',
    component: SearchAutoComplete,
  },
  {
    selector: '.js-search-autocomplete-direct',
    component: SearchAutoCompleteDirect,
  },
  {
    selector: '.js-search-autocomplete-dynamic',
    component: SearchAutoCompleteDynamic,
  },
  {
    selector: '.js-search-forms-container',
    component: SearchTabs,
  },
  {
    selector: '.js-search-results',
    component: SearchResults,
  },
  {
    selector: '.js-search-filters',
    component: SearchResultFilters,
  },
  {
    selector: '.js-result-tools',
    component: SearchResultTools,
  },
  {
    selector: '.js-extensive-search-form',
    component: ExtensiveSearchForm,
  },
  {
    selector: '.js-attached-search-filters',
    component: AttachedSearchFilters,
  },
  {
    selector: '.js-accordion',
    component: Accordion,
  },
  {
    selector: '.js-pageoptions-dropdown',
    component: PageOptions,
  },
  {
    selector: '.js-back-button',
    component: BackButton,
  },
  {
    selector: '.js-cookiebar',
    component: Cookiebar,
  },
  {
    selector: relatedDocumentsSelector,
    component: RelatedDocuments,
  },
];
