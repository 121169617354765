import View from '../View';

class Tables extends View {
  constructor(node) {
    super(node);

    this.table = this.node;
    this.tableParent = this.node.parentNode;
    const index = Array.prototype.indexOf.call(this.tableParent.children, this.table);
    const newParent = document.createElement('div');
    newParent.classList.add('table-wrapper');
    newParent.appendChild(this.table);
    this.tableParent.insertChildAtIndex(newParent, index);
  }
}

export default Tables;
